import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { breakpoints } from '@constants'
import { useHideHeaders, useMatchMedia } from '@hooks'
import settings from '@settings'
import { OnboardingBanner } from '@views/onboarding/OnboardingBanner'
import cn from 'classnames'

import { paths, useAuth, useGetMerchantQuery } from 'mmfintech-backend-api'
import { isMobileDevice, tr } from 'mmfintech-commons'
import { LoginStatusEnum } from 'mmfintech-commons-types'

import HamburgerMenu from '../HamburgerMenu'
import '../HamburgerMenu/hamburger.scss'
import HeaderIcons from '../HeaderIcons'
import JetonLogo from '../JetonLogo'
import { PaymentButtons } from '../PaymentButtons'
import './header.scss'

import LogoImage from '@images/logo.svg'

const MAX_MOBILE_WIDTH = breakpoints.xl

const pathsToHidePaymentButtons = [
  paths.dashboard(),
  paths.settings(),
  paths.banking.deposit(),
  paths.banking.send(),
  paths.banking.exchange(),
  '/cards'
]

function Header() {
  const { data: merchant } = useGetMerchantQuery()
  const userStatus = useAuth()
  const location = useLocation()

  const [opened, setOpened] = useState<boolean>(false)
  const isMobile = useMatchMedia({ breakpoint: MAX_MOBILE_WIDTH, prefix: 'max' })
  const visible = useHideHeaders()

  const { name: merchantName } = merchant || {}

  const isLoggedIn = (): boolean => userStatus === LoginStatusEnum.LOGGED_IN
  const hideMenu = (): void => opened && setOpened(false)
  const shouldHideButtons = (): boolean => pathsToHidePaymentButtons.includes(location.pathname)
  const isDashboard = (): boolean => location.pathname === paths.dashboard()

  const BrandLogoImage = (): JSX.Element => <img src={LogoImage} alt='Logo' />
  const BrandLogo = () => {
    return isMobileDevice() ? (
      <BrandLogoImage />
    ) : isLoggedIn() ? (
      <Link data-test='header-brand-logo' className='brand-logo' to={paths.dashboard()}>
        <BrandLogoImage />
      </Link>
    ) : (
      <a data-test='header-brand-logo' className='brand-logo' href={settings.landingPageUrl}>
        <BrandLogoImage />
      </a>
    )
  }

  useEffect(() => {
    if (!isMobile) hideMenu()
  }, [isMobile])

  useEffect(() => {
    const body = document.body
    if (body) {
      if (opened) {
        window && window.scrollTo(0, 0)
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
  }, [opened])

  return (
    <div className='header-wrapper' data-visible={visible}>
      <div className='header-main-content'>
        {opened && <div className='overlay' onClick={hideMenu} />}
        <div
          className={cn(
            'header-container',
            { 'no-buttons': shouldHideButtons() },
            { 'increased-width': isDashboard() }
          )}>
          {isMobile && <JetonLogo />}
          {!shouldHideButtons() && <PaymentButtons />}
          {!isMobile && <OnboardingBanner />}
        </div>

        {isLoggedIn() ? (
          <div className={cn('header-container-inner', { 'increased-width': isDashboard() })}>
            <HeaderIcons merchantName={merchantName} />
            {isMobile && (
              <div
                data-for={`tooltip-hamburger-menu`}
                data-tip={tr('FRONTEND.HEADER.TOOLTIP.MENU', 'menu')}
                className='header-hamburger'>
                <div className='header-hamburger-icon' onClick={() => setOpened(prevState => !prevState)}>
                  <span className='menu-icon' />
                </div>

                <HamburgerMenu Logo={BrandLogo} opened={opened} hideMenu={() => setOpened(false)} />
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className='bottom-header-banner'>
        {!shouldHideButtons() && <PaymentButtons />}
        {(!shouldHideButtons() || (isDashboard() && isMobile)) && <OnboardingBanner />}
      </div>
    </div>
  )
}

export default Header
