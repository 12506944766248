import { useEffect } from 'react'
import { Link } from 'react-router-dom'

import CoreInput from '@components/CoreInput'
import CoreSelect from '@components/CoreSelect'
import settings from '@settings'

import { useCountries, useGetCardBootstrapDataQuery, useGetProfileQuery } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'
import { CountrySupportedPurposeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import './cardOrderModal.scss'

export const CardOrderForm = ({ formValues, cardIssueError, cardIssueLoading, changePhoneError, cardBrand }) => {
  const { countryOptions } = useCountries(CountrySupportedPurposeEnum.REGISTRATION)
  const {
    data: bootstrapData,
    isLoading,
    error: bootstrapDataError
  } = useGetCardBootstrapDataQuery({ isVirtual: false })

  const { hasValidatedPhone, phoneCheckLoading, phoneCheckError } = useGetProfileQuery(null, {
    selectFromResult: ({ data, isLoading, error }) => {
      return {
        hasValidatedPhone: !!data?.phone3dsVerified,
        phoneCheckError: error,
        phoneCheckLoading: isLoading
      }
    }
  })

  useEffect(() => {
    if (cardIssueError) {
      formValues.handleErrors(cardIssueError)
    }
  }, [cardIssueError])

  useEffect(() => {
    if (formValues && bootstrapData) {
      Object.entries(bootstrapData).forEach(el => {
        const [key, value] = el
        formValues.setValue(key, value)
      })
      if (bootstrapData.address) {
        Object.entries(bootstrapData.address).forEach(el => {
          const [key, value] = el
          if (key === 'addressLine1') {
            formValues.setValue('street', value)
          }
          formValues.setValue(key, value)
        })
      }
    }
  }, [bootstrapData])

  useEffect(() => {
    if (cardBrand == 'VISA') {
      formValues.setAttribute('region', 'required', true)
    }
    return () => {
      formValues.setAttribute('region', 'required', false)
    }
  }, [cardBrand])

  if (isLoading || phoneCheckLoading || cardIssueLoading) {
    return <Spinner />
  }

  return (
    <div className='card-order-form-wrapper'>
      <div className='card-order-form-title'>
        {tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TITLE', 'Confirm Your Delivery Address')}
      </div>
      <div className='card-order-form-subtitle'>
        {tr(
          'FRONTEND.DASHBOARD.CARDS_ORDER.FORM.SUBTITLE',
          'Your contactless debit card will be sent to the address below. Please verify or update the address if needed.'
        )}
      </div>
      <div className='card-order-form-inputs'>
        {/* <div className='card-order-form-name'>
          <CoreSelect
            className='card-order-form-name-title'
            type='default'
            label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.TITLE', 'Title')}
            options={[
              // { label: '', value: null },
              { label: 'Mr.', value: 'Mr.' },
              { label: 'Ms.', value: 'Ms.' }
            ]}
            {...formValues.registerInput('title')}
          />
          <CoreInput
            className='card-order-form-name-value'
            label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.NAME', 'Name')}
            {...formValues.registerInput('firstName')}
          />
        </div>
        <CoreInput
          className='card-order-form-surname'
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.SURNAME', 'Surname')}
          {...formValues.registerInput('lastName')}
        /> 
        <CoreInput
          className='card-order-form-card-holder-name'
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.HOLDER_NAME', 'Card holder name')}
          {...formValues.registerInput('cardHolderName')}
        /> 
        <CoreInput
          className='card-order-form-card-holder-birth-date'
          type='date'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.BIRTH_DATE', 'Date of birth')}
          {...formValues.registerInput('dateOfBirth')}
        /> */}
        <CoreInput
          type='phone'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.PHONE_NUMBER', 'Phone number')}
          {...formValues.registerInput('phoneNumber')}
          disabled={hasValidatedPhone}
        />
        <CoreSelect
          type='country'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.COUNTRY', 'Country')}
          options={countryOptions}
          {...formValues.registerInput('countryCode')}
        />
        {cardBrand === 'VISA' && (
          <CoreInput
            type='text'
            label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.REGION', 'Region')}
            maxLength={50}
            {...formValues.registerInput('region')}
          />
        )}
        <CoreInput
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.CITY', 'City')}
          hint={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.CITY_HINT', 'Please use only Latin letters')}
          {...formValues.registerInput('city')}
        />
        <CoreInput
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.POST_CODE', 'Post code')}
          {...formValues.registerInput('postalCode')}
        />
        <CoreInput
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.ADDRESS', 'Street name')}
          hint={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.ADDRESS_HINT', 'Please use only Latin letters and numbers')}
          {...formValues.registerInput('street')}
        />
        <CoreInput
          type='text'
          label={tr('FRONTEND.DASHBOARD.CARDS_ORDER.LABEL.STREET_NUMBER', 'Street number')}
          {...formValues.registerInput('streetNumber')}
        />
      </div>
      <ErrorDisplay error={[cardIssueError, bootstrapDataError, phoneCheckError, changePhoneError]} />
      <div className='card-order-form-footer'>
        {tr(
          'FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TERMS_AGREEMENT',
          'By tapping Accept and complete order, you have read and accept our'
        )}
        <Link to={{ pathname: settings.landingPageUrl + '/terms-and-conditions' }} target='_blank' data-test='TOC'>
          <span className='ml-2 mr-2'>{tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.TOC', 'Terms and Conditions')}</span>
        </Link>
        {tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.AND', 'and')}
        <Link to={{ pathname: settings.landingPageUrl + '/privacy-policy' }} target='_blank' data-test='privacy'>
          <span className='ml-2'>{tr('FRONTEND.DASHBOARD.CARDS_ORDER.FORM.PRIVACY', 'Privacy Policy')}</span>
        </Link>
      </div>
    </div>
  )
}
