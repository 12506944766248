import React, { useContext, useEffect } from 'react'

import GoogleAuthenticator from '@components/GoogleAuthenticator'
import EmailIcon from '@images/icons/email-icon.svg?react'
import GaIcon from '@images/icons/ga-icon.svg?react'
import ResendIcon from '@images/icons/resend-icon.svg?react'
import SmartIdIcon from '@images/icons/smart-id-icon.svg?react'
import SMSIcon from '@images/icons/sms-icon.svg?react'
import cn from 'classnames'

import { globalSettings, useOtpQry } from 'mmfintech-backend-api'
import { OtpContext, isValidArray, tr, useValidUntilTimer } from 'mmfintech-commons'
import { ChallengePurposeEnum, LoginStatusEnum, TwoFactorType, TwoFactorTypeEnum } from 'mmfintech-commons-types'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import CoreButton from '../CoreButton'
import OtpInput from '../OtpInput/OtpInput'
import './otp.scss'

import EmailVerifyIcon from '@images/icons/e-mail-verification-icon.png'
import SmartIdVerifyIcon from '@images/icons/smart-id-verification-icon.svg'
import SMSVerifyIcon from '@images/icons/sms-verification-icon.svg'

interface prepareTitle {
  twoFactorType: TwoFactorType
  userStatus: any
}

const CHANGE_2FA_OPTIONS = [
  ChallengePurposeEnum.CHANGE_2FA,
  ChallengePurposeEnum.CHANGE_2FA_TOTP,
  ChallengePurposeEnum.CHANGE_2FA_PHONE
]

export const prepareTitle = ({ twoFactorType, userStatus }: prepareTitle) => {
  if (twoFactorType === TwoFactorTypeEnum.SMART_ID) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMARTID', 'SmartID Verification')
  }

  if (twoFactorType === TwoFactorTypeEnum.SMS) {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_SMS', 'SMS Verification')
    }

    return tr('FRONTEND.VERIFY_OTP.TITLE_SMS', 'SMS Confirmation')
  }

  if (twoFactorType === TwoFactorTypeEnum.TOTP) {
    if (userStatus === LoginStatusEnum.LOGGED_IN) {
      return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_TOTP', 'Google Authenticator verification')
    }
    return tr('FRONTEND.VERIFY_OTP.TITLE_TOTP', 'Google Authenticator confirmation')
  }

  if (userStatus === LoginStatusEnum.SECOND_FACTOR || userStatus === LoginStatusEnum.LOGGED_IN) {
    return tr('FRONTEND.VERIFY_OTP.TITLE_VERIFY_EMAIL', 'E-mail Verification')
  }

  return tr('FRONTEND.VERIFY_OTP.TITLE_EMAIL', 'E-mail Confirmation')
}

export const Otp = () => {
  const {
    code,
    codeSize,
    attempts,
    challenge,
    availableTwoFactorTypes,
    handleCodeChanged,
    handleChangeOtpType,
    handleSubmit,
    handleResend,
    handleReissueSmartId,
    verifyOtpError,
    verifyOtpFetching,
    resendOtpFetching,
    resendOtpError,
    error,
    resendTimer
  } = useOtpQry()

  const timer = useValidUntilTimer()
  const { setOtpOnError, setOtpOnSuccess } = useContext(OtpContext)

  const { sentTo, twoFactorType, totpSecretKeyUri, challengePurpose } = challenge || {}

  useEffect(() => {
    return () => {
      setOtpOnSuccess(null)
      setOtpOnError(null)
    }
  }, [])

  const alternativeOtpMethods = (method: string) => {
    switch (method) {
      case TwoFactorTypeEnum.SMS:
        return (
          <div className='alternative-method-wrapper'>
            <SMSIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMS.LABEL', 'SMS')}</span>
          </div>
        )
      case TwoFactorTypeEnum.SMART_ID:
        return (
          <div className='alternative-method-wrapper'>
            <SmartIdIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.SMART_ID.LABEL', 'Smart ID')}</span>
          </div>
        )
      case TwoFactorTypeEnum.EMAIL:
        return (
          <div className='alternative-method-wrapper'>
            <EmailIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.EMAIL.LABEL', 'Email')}</span>
          </div>
        )
      case TwoFactorTypeEnum.TOTP:
        return (
          <div className='alternative-method-wrapper'>
            <GaIcon />
            <span className='alternative-method-label'>{tr('FRONTEND.VERIFY_OTP.GA.LABEL', 'GA code')}</span>
          </div>
        )
      default:
        return null
    }
  }

  const mainIcon = () => {
    switch (twoFactorType) {
      case TwoFactorTypeEnum.SMART_ID:
        return SmartIdVerifyIcon
      case TwoFactorTypeEnum.SMS:
        return SMSVerifyIcon
      case TwoFactorTypeEnum.EMAIL:
        return EmailVerifyIcon

      default:
        return EmailVerifyIcon
    }
  }

  const internalSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    void handleSubmit()
  }

  return (
    <form className='otp-wrapper' noValidate onSubmit={internalSubmit}>
      {totpSecretKeyUri ? (
        <GoogleAuthenticator totpSecretKeyUri={totpSecretKeyUri} />
      ) : (
        <>
          <div style={{ marginTop: '-1rem' }}>
            <img src={mainIcon() as string} alt='email-verify-icon' />
          </div>
          {twoFactorType === TwoFactorTypeEnum.SMART_ID ? (
            <div data-test='otp-subtitle-smartid' className='subtitle-smartid'>
              <span>{tr('FRONTEND.VERIFY_OTP.SMART_ID.TEXT_1', 'We’ve sent a notification to your device.')}</span>
              <span>{tr('FRONTEND.VERIFY_OTP.SMART_ID.TEXT_2', 'Please check to verify it’s you.')}</span>
            </div>
          ) : (
            <div data-test='otp-subtitle' className='subtitle'>
              {twoFactorType === TwoFactorTypeEnum.TOTP
                ? tr('FRONTEND.VERIFY_OTP.TOTP_TARGET_LABEL', 'Please, enter the verification code:')
                : tr('FRONTEND.VERIFY_OTP.TARGET_LABEL', 'Please enter the verification code that we’ve sent')}{' '}
              <p data-test='otp-send-to'>{sentTo}</p>
            </div>
          )}
        </>
      )}

      <div className='otp-error-container'>
        <ErrorDisplay error={[verifyOtpError, resendOtpError]} />
      </div>

      {resendOtpFetching || verifyOtpFetching ? (
        <Spinner />
      ) : (
        <div className='content-wrapper'>
          {twoFactorType === TwoFactorTypeEnum.SMART_ID ? (
            <>
              {error ? (
                <ErrorDisplay error={[error]} />
              ) : (
                <CoreButton
                  title={tr('FRONTEND.VERIFY_OTP.SMART_ID.SEND_AGAIN', 'Send Again')}
                  LeftIcon={<ResendIcon />}
                  onClick={handleReissueSmartId}
                  size='normal'
                  type='button'
                  variation='secondary'
                />
              )}
            </>
          ) : (
            <>
              <OtpInput
                codeLength={codeSize}
                onChange={value => handleCodeChanged(value)}
                disabled={timer.expired || attempts >= globalSettings.otpMaxAttempts}
              />
              <div className='countdown'>{timer.formattedTime}</div>
              {challengePurpose !== ChallengePurposeEnum.CHANGE_2FA_TOTP &&
                twoFactorType !== TwoFactorTypeEnum.TOTP && (
                  <div>
                    {attempts < globalSettings.otpMaxAttempts ? (
                      <CoreButton
                        data-test='otp-re-send-button'
                        onClick={handleResend}
                        disabled={!resendTimer.expired}
                        LeftIcon={resendTimer.expired ? <ResendIcon /> : null}
                        type='button'
                        title={
                          resendTimer.expired
                            ? tr('FRONTEND.VERIFY_OTP.RESEND_CODE', 'Resend')
                            : (resendTimer.remainingTime as string)
                        }
                        variation={'tertiary'}
                        size='normal'
                      />
                    ) : (
                      <div className=''>
                        {tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')}
                      </div>
                    )}
                  </div>
                )}

              {isValidArray(availableTwoFactorTypes?.filter(type => type !== twoFactorType)) &&
                !CHANGE_2FA_OPTIONS.includes(challengePurpose as ChallengePurposeEnum) && (
                  <>
                    <div className='otp-modal-methods-title'>
                      {tr('FRONTEND.VERIFY_OTP.NOT_RECEIVED', "Haven't received it? Retry via:")}
                    </div>

                    <div className={cn('otp-modal-methods-actions', { disable: !resendTimer.expired })}>
                      {attempts < globalSettings.otpMaxAttempts ? (
                        <>
                          {availableTwoFactorTypes
                            .filter(type => type !== twoFactorType)
                            .map(type => {
                              return (
                                <CoreButton
                                  key={type}
                                  data-test='otp-alternative-method'
                                  onClick={() => resendTimer.expired && handleChangeOtpType({ type })}
                                  type='button'
                                  title={alternativeOtpMethods(type)}
                                  variation={'secondary'}
                                  className='otp-alternative-method'
                                  size='normal'
                                />
                              )
                            })}
                        </>
                      ) : (
                        tr('FRONTEND.VERIFY_OTP.NO_MORE_ATTEMPTS', 'No more verification attempts.')
                      )}
                    </div>
                  </>
                )}

              <div className='continue-button'>
                <CoreButton
                  data-test='otp-confirm-button'
                  fullWidth
                  title={tr('FRONTEND.BUTTONS.CONFIRM', 'Confirm')}
                  disabled={code.length < codeSize || timer.expired || attempts >= globalSettings.otpMaxAttempts}
                  size='normal'
                  variation={'primary'}
                />
              </div>
            </>
          )}
        </div>
      )}
    </form>
  )
}
