import { Dispatch, SetStateAction, useContext } from 'react'
import toast from 'react-hot-toast'
import ReactTooltip from 'react-tooltip'

import cn from 'classnames'

import { useLazyShowCardPinQuery, useMerchantAccounts, useGetMerchantQuery, useGetPrepaidCardDetailsQuery } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, formatMoney, isValidArray, tr, translateError } from 'mmfintech-commons'
import { AccountBalanceResponse } from 'mmfintech-commons-types'

import CoreTabs from '@components/CoreTabs'
import CoreButton from '@components/CoreButton'
import CardSettingsItem from './CardSettingsItem'
import { CardSettingsList } from './CardSettingsList'

import EditCardPin from './modals/EditCardPin'
import ReplaceCard from './modals/ReplaceCard'
import TerminateCard from './modals/TerminateCard'
import AdvancedCardOptions from './modals/AdvancedCardOptions'
import { UnfreezeCard } from './modals/UnfreezeCard'
import { StatementHistory } from './modals/StatementHistory'
import { CardPin } from './modals/CardPin'
import SpendFromSelectedAccounts from './modals/SpendFromSelectedAccounts'

import ActiveCardIcon from '@images/icons/active-card.svg?react'
import CloudIcon from '@images/icons/cloud.svg?react'
import CardIcon from '@images/icons/credit-card-icon.svg?react'
import CrossIcon from '@images/icons/cross-button-icon.svg?react'
import PhysicalCardIcon from '@images/icons/physical-card-icon.svg?react'
import BlockedBalanceIcon from '@images/icons/show-card-block.svg?react'


import './cardSettings.scss'

const TooltipsConstant = {
  TotalBalance: {
    localizationKey: 'FRONTEND.CARDS.TOTAL_BALANCE_TOOLTIP',
    tooltip: 'The full amount in your account, including blocked funds.',
    key: 'total-balance'
  },
  BlockedBalance: {
    localizationKey: 'FRONTEND.CARDS.BLOCKED_BALANCE_TOOLTIP',
    tooltip: 'Funds on hold for pending transactions.',
    key: 'blocked-balance'
  },
  AvailableBalance: {
    localizationKey: 'FRONTEND.CARDS.AVAILABLE_BALANCE_TOOLTIP',
    tooltip: 'The amount you can use right now.',
    key: 'available-balance'
  }
}

interface CardSettingsProps {
  onClose?: () => void
  tabOptions?: { label: string; value: string }[]
  cardType?: 'virtual' | 'physical'
  setCardType?: Dispatch<SetStateAction<'virtual' | 'physical'>>
  cardNumber?: string
  disableTabSelect?: boolean
  cardDetails?: any
  accountDetails?: AccountBalanceResponse
}

export const CardSettings = ({
  onClose,
  tabOptions = [],
  cardType,
  setCardType,
  cardNumber = '',
  disableTabSelect = false,
  cardDetails,
  accountDetails
}: CardSettingsProps) => {
  const { modalShow } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)
  const [showCardPin] = useLazyShowCardPinQuery()

  const { activeAccounts } = useMerchantAccounts()
  const { data: merchant } = useGetMerchantQuery()

  const { capabilities } = merchant || {}
  const { enableCardMultispending } = (capabilities as any) || {}

  const { data: selectedCardDetails } = useGetPrepaidCardDetailsQuery(
    { prepaidCardId: cardDetails?.id },
    { skip: !cardDetails?.id }
  )
  const cardAccount = activeAccounts?.find(account => !!account.prepaidCardAccount) || {}
  const cardBankDetails = () => {
    if (isValidArray(cardAccount?.dedicatedMerchantBankAccounts)) {
      return cardAccount?.dedicatedMerchantBankAccounts?.[0]
    } else {
      return {}
    }
  }

  const isValidCardSetting = (key: string) => {
    const fail1 = cardDetails && cardDetails?.status != 'FROZEN' && key == 'unfreezeCard'
    const fail2 = key === 'changePin' && cardType === 'virtual' && cardDetails?.cardBrand !== 'VISA'
    const fail3 = key === 'showPin' && cardType === 'virtual' && cardDetails?.cardBrand !== 'VISA'
    return !fail1 && !fail2 && !fail3
  }

  const handleOpenModal = async (key: string, options?: any) => {
    switch (key) {
      case 'spendFrom':
        modalShow({
          options: { closeOnClickOutside: false },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.SPEND_FROM.TITLE', 'Spend from'),
          content: (
            <SpendFromSelectedAccounts
              isAllAccountsSpendingEnabled={selectedCardDetails?.allAccountsSpendingEnabled}
              prepaidCardId={cardDetails?.id}
            />
          )
        })
        break
      case 'advancedOptions':
        modalShow({
          options: { closeOnClickOutside: false },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.ADVANCED_OPTIONS.TITLE', 'Advanced card options'),
          content: (
            <AdvancedCardOptions
              supportedOperations={cardDetails?.supportedOperations}
              prepaidCardId={cardDetails?.id}
            />
          )
        })
        break
      case 'changePin':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.CHANGE_PIN.TITLE', 'Change PIN'),
          content: <EditCardPin prepaidCardId={cardDetails?.id} isSet={true} />
        })
        break
      case 'showPin':
        setOtpOnSuccess(() => (response: any) => {
          if (response) {
            const pin = typeof response === 'string' || typeof response === 'number' ? response : response?.pin
            setTimeout(() => {
              modalShow({
                header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Card PIN'),
                content: <CardPin cardPin={pin} />
              })
            }, 0)
          }
        })
        try {
          await showCardPin({ prepaidCardId: cardDetails?.id }).unwrap()
        } catch (err) {
          toast.error(translateError(err))
          setOtpOnSuccess(null)
        }
        break
      case 'replaceCard':
        modalShow({
          options: { closeOnClickOutside: true },
          header:
            cardType == 'physical'
              ? tr('FRONTEND.CARDS.SETTINGS.MODAL.REPLACE_CARD.PHYSICAL.TITLE', 'Replace your Physical card')
              : tr('FRONTEND.CARDS.SETTINGS.MODAL.REPLACE_CARD.VIRTUAL.TITLE', 'Replace your Virtual card'),
          content: <ReplaceCard cardNumber={options?.cardNumber} cardType={options?.cardType} />
        })
        break
      case 'unfreezeCard':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.UNFREEZE_CARD.TITLE', 'Unfreeze your card'),
          content: <UnfreezeCard prepaidCardId={cardDetails?.id} />
        })
        break
      case 'statementHistory':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.STATEMENT_HISTORY.TITLE', 'Statement history'),
          content: <StatementHistory cardDetails={cardDetails} />
        })
        break
      case 'terminateCard':
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.TERMINATE_CARD.TITLE', 'Terminate card'),
          content: <TerminateCard prepaidCardId={cardDetails?.id} />
        })
        break
      default:
      //nothing
    }
  }

  return (
    <div className='card-settings-wrapper'>
      <div className='card-settings-top-section'>
        <div className='card-settings-title'>{tr('FRONTEND.CARDS.SETTINGS.TITLE', 'Card settings')}</div>
        <CoreButton
          variation='secondary'
          data-test='activities-menu-close-button'
          onClick={() => onClose()}
          size='small'
          collapsed
          CollapsedIcon={<CrossIcon />}
        />
      </div>
      <CoreTabs
        onChangeTab={tab => setCardType(tab)}
        tabs={tabOptions}
        preselected={cardType}
        disabled={disableTabSelect}
      />
      <div className='card-settings-bank-details'>
        <div className={cn('card-settings-card-number', { virtual: cardType == 'virtual' })}>
          <div className='card-setting-number-value'>
            {cardNumber && cardNumber.length > 4
              ? cardNumber
                .toString()
                .match(/.{1,4}/g)
                .join(' ')
              : `**** **** **** ${cardNumber.slice(-4)}`}
          </div>
          {cardType === 'physical' ? <PhysicalCardIcon /> : <CloudIcon />}
        </div>
        <div className='card-holder-name'>{cardDetails?.cardHolderName}</div>
        <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.IBAN', 'IBAN')}</span>
          <span className='detail-value'>{cardBankDetails()?.iban}</span>
        </div>
        <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.BANK_NAME', 'Bank name')}</span>
          <span className='detail-value'>{cardBankDetails()?.bankName}</span>
        </div>
        <div className='card-bank-detail'>
          <span className='detail-label'>{tr('FRONTEND.CARDS.SETTINGS.DETAILS.BANK_CODE', 'Bank Code')}</span>
          <span className='detail-value'>{cardBankDetails()?.bankCode}</span>
        </div>
      </div>
      {!enableCardMultispending && (
        <div className='card-settings-balances'>
          <div className='card-settings-balances-title'>
            {tr('FRONTEND.CARDS.SETTINGS.DETAILS.CARD_BALANCE', 'Card balance')}
          </div>
          <div className='card-bank-detail'>
            <span
              className='detail-label'
              data-for={`card-${TooltipsConstant.TotalBalance.key}`}
              data-tip={tr(TooltipsConstant.TotalBalance.localizationKey, TooltipsConstant.TotalBalance.tooltip)}>
              <CardIcon />
              {tr('FRONTEND.CARDS.SETTINGS.DETAILS.TOTAL_BALANCE', 'Total balance')}
              <ReactTooltip
                delayShow={200}
                id={`card-${TooltipsConstant.TotalBalance.key}`}
                className='custom-tool-tip-component'
                textColor='black'
                backgroundColor='white'
                place='right'
                effect='solid'
              />
            </span>
            <span className='detail-value large-value'>
              {formatMoney(accountDetails?.balance, cardDetails?.currency)}
            </span>
          </div>
          <div className='card-bank-detail'>
            <span
              className='detail-label'
              data-for={`card-${TooltipsConstant.AvailableBalance.key}`}
              data-tip={tr(
                TooltipsConstant.AvailableBalance.localizationKey,
                TooltipsConstant.AvailableBalance.tooltip
              )}>
              <ActiveCardIcon />
              {tr('FRONTEND.CARDS.SETTINGS.DETAILS.AVAILABLE_BALANCE', 'Available balance')}
              <ReactTooltip
                delayShow={200}
                id={`card-${TooltipsConstant.AvailableBalance.key}`}
                className='custom-tool-tip-component'
                textColor='black'
                backgroundColor='white'
                place='right'
                effect='solid'
              />
            </span>
            <span className='detail-value'>{formatMoney(accountDetails?.availableBalance, cardDetails?.currency)}</span>
          </div>
          <div className='card-bank-detail'>
            <span
              className='detail-label'
              data-for={`card-${TooltipsConstant.BlockedBalance.key}`}
              data-tip={tr(TooltipsConstant.BlockedBalance.localizationKey, TooltipsConstant.BlockedBalance.tooltip)}>
              <BlockedBalanceIcon />
              {tr('FRONTEND.CARDS.SETTINGS.DETAILS.BLOCKED_BALANCE', 'Blocked balance')}
              <ReactTooltip
                delayShow={200}
                id={`card-${TooltipsConstant.BlockedBalance.key}`}
                className='custom-tool-tip-component'
                textColor='black'
                backgroundColor='white'
                place='right'
                effect='solid'
              />
            </span>
            <span className='detail-value'>{formatMoney(accountDetails?.blockedBalance, cardDetails?.currency)}</span>
          </div>
        </div>
      )}
      <div className='card-settings-spend-section'>
        <div className='caption'>{tr('FRONTEND.CARDS.SETTINGS.SPEND_FROM.TITLE', 'Spend from')}</div>
        {CardSettingsList.spendFrom.map(element => {
          const { icon, titleKey, titleValue, key, titleAltKey, titleAltValue } = element
          return (
            <CardSettingsItem
              key={titleKey}
              icon={icon}
              title={selectedCardDetails?.allAccountsSpendingEnabled ? tr(titleKey, titleValue) : tr(titleAltKey, titleAltValue)}
              clickHandler={() => handleOpenModal(key)}
            />
          )
        })}
      </div>
      <div className='card-settings-manage-section'>
        <div className='caption'>{tr('FRONTEND.CARDS.SETTINGS.MANAGE', 'Manage')}</div>
        {CardSettingsList.manage.map(element => {
          const { icon, titleKey, titleValue, key } = element
          return (
            <CardSettingsItem
              key={titleKey}
              icon={icon}
              title={tr(titleKey, titleValue)}
              clickHandler={() => handleOpenModal(key)}
            />
          )
        })}
      </div>
      {CardSettingsList.settings.some(entry => isValidCardSetting(entry.key)) && (
        <div className='card-settings-actions-section'>
          <div className='caption'>{tr('FRONTEND.CARDS.SETTINGS.SETTINGS', 'Settings')}</div>
          {CardSettingsList.settings.map(element => {
            const { icon, titleKey, titleValue, key } = element
            return isValidCardSetting(key) ? (
              <CardSettingsItem
                key={titleKey}
                icon={icon}
                title={tr(titleKey, titleValue)}
                clickHandler={() => handleOpenModal(key, { cardNumber, cardType })}
              />
            ) : null
          })}
        </div>
      )}
      <div className='card-settings-terminate-section'>
        {CardSettingsList.rest.map(element => {
          const { icon, titleKey, titleValue, key } = element
          return (
            <CardSettingsItem
              key={titleKey}
              icon={icon}
              title={tr(titleKey, titleValue)}
              clickHandler={() => handleOpenModal(key)}
            />
          )
        })}
      </div>
    </div>
  )
}
