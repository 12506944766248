import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import CoreButton from '@components/CoreButton'
import useOnboarding from '@views/onboarding/useOnboarding'

import { resetEmailVerification } from 'mmfintech-backend-api'
import { tr } from 'mmfintech-commons'

import { WelcomeContent2 } from './WelcomeContent2'
import './welcomeModal.scss'

type TWelcomeModalProps = {}

export const WelcomeModal = ({}: TWelcomeModalProps) => {
  const { startOrContinueOnboarding } = useOnboarding()
  const dispatch = useDispatch()

  const onResetEmailVerification = () => {
    dispatch(resetEmailVerification())
  }

  useEffect(() => {
    return () => {
      onResetEmailVerification()
    }
  }, [])

  return (
    <div className='welcome-wrapper'>
      <div className='welcome-container' data-test='welcome-modal'>
        <WelcomeContent2 />

        <div className='footer-buttons'>
          <CoreButton
            title={tr('FRONTEND.WELCOME.MODAL.START_ONBOARDING', 'Get Started')}
            onClick={startOrContinueOnboarding}
            size='large'
            fullWidth
          />
        </div>
      </div>
    </div>
  )
}
