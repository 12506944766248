import { SetStateAction, useEffect, useState } from 'react'

import cn from 'classnames'

import { isValidArray, isValidString, tr } from 'mmfintech-commons'
import { useCardsSupportedQry, useGetPrepaidCardsQuery, useGetMerchantQuery } from 'mmfintech-backend-api'

import CheckIcon from '@images/icons/check.svg?react'
import CoreCheckbox from '@components/CoreCheckbox'
import ReactTooltip from 'react-tooltip'
import VirtualCardIcon from '@images/virtual-card-without-brand.svg?react'
import PhysicalCardIcon from '@images/physical-card-without-brand.svg?react'

import { CardBrand } from './CardBrand'
import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import { CardGateway_PrepaidCardResponse } from 'mmfintech-commons-types'

import './cardOrderModal.scss'

interface CardOrderTypeSelectionProps {
  issuingError?: any
  cardType?: 'virtual' | 'physical'
  setSelectedPhysicalCardCurrency?: (value: any) => void
  setSelectedVirtualCardCurrency?: (value: any) => void
  setCardType?: (value: SetStateAction<'virtual' | 'physical'>) => void
  setSelectedPhysicalCardBrand?: (value: any) => void
  setSelectedVirtualCardBrand?: (value: any) => void
  selectedPhysicalCardBrand?: any
  selectedVirtualCardBrand?: any
  selectedPhysicalCardCurrency?: any
  selectedVirtualCardCurrency?: any
}

export const CardOrderTypeSelection = (props: CardOrderTypeSelectionProps) => {
  const {
    // selectedPhysicalCardCurrency,
    // selectedVirtualCardCurrency,
    issuingError,
    cardType,
    setCardType,
    setSelectedPhysicalCardBrand,
    setSelectedVirtualCardBrand,
    selectedPhysicalCardBrand,
    selectedVirtualCardBrand,
    setSelectedPhysicalCardCurrency,
    setSelectedVirtualCardCurrency
  } = props

  const {
    findCurrencies,
    supportedCards,
    supportedPhysical,
    supportedVirtual,
    supportedCardsError,
    supportedCardsLoading
  } = useCardsSupportedQry()

  const { data: merchant } = useGetMerchantQuery()
  const { capabilities } = merchant || {}
  const { enableCardMultispending } = (capabilities as any) || {}

  const { data: activeCards, isLoading: activeCardsLoading, error: activeCardsError } = useGetPrepaidCardsQuery(null)

  const [enablePhysicalCardBrandOptions, setEnablePhysicalCardBrandOptions] = useState<boolean>(null)
  const [enableVirtualCardBrandOptions, setEnableVirtualCardBrandOptions] = useState<boolean>(null)
  const [disablePhysicalCard, setDisablePhysicalCard] = useState<boolean | CardGateway_PrepaidCardResponse>(false)
  const [disableVirtualCard, setDisableVirtualCard] = useState<boolean | CardGateway_PrepaidCardResponse>(false)

  useEffect(() => {
    setEnablePhysicalCardBrandOptions(supportedPhysical?.length > 1)
    setEnableVirtualCardBrandOptions(supportedVirtual?.length > 1)

    if (
      !isValidString(selectedPhysicalCardBrand) &&
      isValidArray(supportedPhysical) &&
      supportedPhysical.length === 1
    ) {
      setSelectedPhysicalCardBrand(supportedPhysical?.[0])
      setSelectedPhysicalCardCurrency(findCurrencies('PHYSICAL', supportedPhysical?.[0])?.[0])
    }
    if (!isValidString(selectedVirtualCardBrand) && isValidArray(supportedVirtual) && supportedVirtual.length === 1) {
      setSelectedVirtualCardBrand(supportedVirtual?.[0])
      setSelectedVirtualCardCurrency(findCurrencies('VIRTUAL', supportedVirtual?.[0])?.[0])
    }

    if (cardType === 'physical' && isValidArray(supportedPhysical)) {
      setSelectedPhysicalCardCurrency(findCurrencies(cardType?.toLocaleUpperCase(), selectedPhysicalCardBrand)?.[0])
    }
    if (cardType === 'virtual' && isValidArray(supportedVirtual)) {
      setSelectedVirtualCardCurrency(findCurrencies(cardType?.toLocaleUpperCase(), selectedVirtualCardBrand)?.[0])
    }
  }, [supportedCards, cardType, selectedPhysicalCardBrand, selectedVirtualCardBrand])

  // const hasActivePhysicalCard = () => isValidArray(activeCards) && activeCards.some(card => !card.isVirtual)
  // const hasActiveVirtualCard = () => isValidArray(activeCards) && activeCards.some(card => !!card.isVirtual)

  useEffect(() => {
    setDisablePhysicalCard(!isValidArray(supportedPhysical))
    setDisableVirtualCard(!isValidArray(supportedVirtual))
  }, [activeCards, supportedCards])

  // useEffect(() => {
  //   if (cardType) {
  //     const physicalCurrency = findCurrencies(cardType?.toLocaleUpperCase(), selectedPhysicalCardBrand)
  //     const virtualCurrency = findCurrencies(cardType?.toLocaleUpperCase(), selectedVirtualCardBrand)
  //     if (cardType === 'physical' && isValidArray(physicalCurrency)) {
  //       setSelectedPhysicalCardCurrency(physicalCurrency[0])
  //     } else {
  //       setSelectedVirtualCardCurrency(virtualCurrency[0])
  //     }
  //   }
  // }, [selectedPhysicalCardBrand, selectedVirtualCardBrand, cardType])

  return (
    <>
      {supportedCardsLoading || activeCardsLoading ? (
        <Spinner />
      ) : (
        <div className='card-order-type-selection'>
          <ErrorDisplay error={[issuingError, supportedCardsError, activeCardsError]} />

          <div
            className={cn('card-order-flag-wrapper', {
              open: cardType === 'physical' && enablePhysicalCardBrandOptions,
              'card-unavailable': disablePhysicalCard
            })}
            // data-for={`card-order-tip`}
            // data-tip={
            //   isValidArray(activeCards) && activeCards.find(card => card.isVirtual == false)
            //     ? tr('FRONTEND.CARD_ORDER.TOOLTIP_ALREADY_HAVE_PHYSICAL_CARD', 'You already have physical card.')
            //     : !isValidArray(supportedPhysical)
            //       ? tr(
            //           'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_ISSUING_PHYSICAL_CARD',
            //           'Physical card issuing is not available for the selected currency'
            //         )
            //       : null
            // }
          >
            <div
              className={cn('card-order-card-type-container', {
                'card-selected': cardType === 'physical'
              })}
              onClick={() => !disablePhysicalCard && setCardType('physical')}>
              <div className='card-order-check-icon'>
                <CheckIcon />
              </div>
              <div className='card-order-card-icon'>
                <PhysicalCardIcon />
                <CardBrand
                  brand={cardType === 'physical' && (selectedPhysicalCardBrand || '')}
                  logoOnly
                  className='card-brand-icon'
                />
              </div>
              <div className='card-order-card-content'>
                <div className='card-order-card-title'>
                  {tr('FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TITLE', 'Physical card')}
                </div>
                <div className='card-order-card-text'>
                  {enableCardMultispending
                    ? tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT_WITH_MULTI_CURRENCIES',
                        `A contactless debit card will be delivered to you. Card will be allowed to spend from all currency accounts. If you want to change this, go to card options after the card is issued and adjust.`
                      )
                    : tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT_WITH_CURRENCY',
                        `A contactless debit card will be delivered to you.`
                      )}
                  {/* {tr(
                    'FRONTEND.DASHBOARD.CARDS_ORDER.PHYSICAL.TEXT_WITH_CURRENCY',
                    `A contactless debit card will be delivered to you. Your card balance will be {{CURRENCY}} with a new account.`
                  ).replace('{{CURRENCY}}', selectedPhysicalCardCurrency || 'GBP')} */}
                </div>
              </div>
            </div>
            {enablePhysicalCardBrandOptions && (
              <div className={cn('card-order-choose-card-brand', { open: cardType === 'physical' })}>
                {isValidArray(supportedPhysical) &&
                  supportedPhysical.map(brand => {
                    return (
                      <div
                        onClick={() => setSelectedPhysicalCardBrand(brand)}
                        className='card-order-brand-wrapper'
                        key={'ph' + brand}>
                        <CardBrand brand={brand} />
                        <span>{brand}</span>
                        <CoreCheckbox
                          className='card-order-checkbox'
                          value={brand as string}
                          checked={selectedPhysicalCardBrand === brand}
                          type='circle'
                        />
                      </div>
                    )
                  })}
              </div>
            )}
          </div>

          {/* virtual */}
          <div
            className={cn('card-order-flag-wrapper', {
              open: cardType === 'virtual' && enableVirtualCardBrandOptions,
              'card-unavailable': disableVirtualCard
            })}
            data-for={`card-order-tip`}
            data-tip={
              !isValidArray(supportedVirtual)
                ? tr(
                    'FRONTEND.CARD_ORDER.TOOLTIP_UNAVAILABLE_ISSUING_VIRTUAL_CARD',
                    'Virtual card issuing is not available for the selected currency.'
                  )
                : null
            }>
            <div
              className={cn('card-order-card-type-container', { 'card-selected': cardType === 'virtual' })}
              onClick={() => !disableVirtualCard && setCardType('virtual')}>
              <div className='card-order-check-icon'>
                <CheckIcon />
              </div>
              <div className='card-order-card-icon'>
                <VirtualCardIcon />
                <CardBrand
                  brand={cardType === 'virtual' && (selectedVirtualCardBrand || '')}
                  logoOnly
                  className='card-brand-icon'
                />
              </div>
              <div className='card-order-card-content'>
                <div className='card-order-card-title'>
                  {tr('FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TITLE', 'Virtual card')}
                </div>
                <div className='card-order-card-text'>
                  {enableCardMultispending
                    ? tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT_WITH_MULTI_CURRENCIES',
                        `Get virtual card and manage your online payments. Card will be allowed to spend from all currency accounts. If you want to change this, go to card options after the card is issued and adjust.`
                      )
                    : tr(
                        'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT_WITH_CURRENCY',
                        `Get virtual card and manage your online payments.`
                      )}
                  {/* {tr(
                    'FRONTEND.DASHBOARD.CARDS_ORDER.VIRTUAL.TEXT_WITH_CURRENCY',
                    `Get virtual card and manage your online payments. Your card balance will be {{CURRENCY}} with a new account.`
                  ).replace('{{CURRENCY}}', selectedVirtualCardCurrency || 'GBP')} */}
                </div>
              </div>
            </div>
            {enableVirtualCardBrandOptions && (
              <div className={cn('card-order-choose-card-brand', { open: cardType === 'virtual' })}>
                {isValidArray(supportedVirtual) &&
                  supportedVirtual.map(brand => {
                    return (
                      <div
                        onClick={() => setSelectedVirtualCardBrand(brand)}
                        className='card-order-brand-wrapper'
                        key={'vi' + brand}>
                        <CardBrand brand={brand} />
                        <span>{brand}</span>
                        <CoreCheckbox
                          className='card-order-checkbox'
                          value={brand as string}
                          checked={selectedVirtualCardBrand === brand}
                          type='circle'
                        />
                      </div>
                    )
                  })}
              </div>
            )}
          </div>
          <ReactTooltip
            id='card-order-tip'
            textColor='black'
            backgroundColor='white'
            effect='solid'
            className='custom-tool-tip-component'
            padding='10px 6px'
            place='top'
          />
        </div>
      )}
    </>
  )
}
