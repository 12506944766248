import { useEffect, useState } from 'react'

import { formatMoney, tr } from 'mmfintech-commons'
import { ErrorDisplay, Preloader, SwitchInput } from 'mmfintech-portal-commons'
import CoreCheckbox from '@components/CoreCheckbox'
import { CurrencyIcon } from '@components/CurrencyIcon'
import {
  useGetPrepaidCardAvailableAccountsQuery,
  useAddPrepaidCardExcludedAccountMutation,
  useRemovePrepaidCardExcludedAccountMutation,
  useUpdatePrepaidCardAllAccountsSpendingMutation
} from 'mmfintech-backend-api'


interface AdvanceCardOptionsProps {
  isAllAccountsSpendingEnabled?: boolean
  prepaidCardId?: string
}

const SpendFromSelectedAccounts = ({ isAllAccountsSpendingEnabled, prepaidCardId }: AdvanceCardOptionsProps) => {

  const [allAccounts, setAllAccounts] = useState<boolean>(!!isAllAccountsSpendingEnabled)
  const [accountState, setAccountState] = useState<any[]>()

  const { data: accounts, error: accountsError, isLoading: accountsFetching } = useGetPrepaidCardAvailableAccountsQuery({ prepaidCardId })
  const [addAccountExclusion, { isLoading: addAccountFetching, error: addAccountError }] = useAddPrepaidCardExcludedAccountMutation()
  const [removeAccountExclusion, { isLoading: removeAccountFetching, error: removeAccountError }] = useRemovePrepaidCardExcludedAccountMutation()
  const [updateAllAccountSpending, { isLoading: allAccountsSpendingFetching, error: allAccountsSpendingError }] = useUpdatePrepaidCardAllAccountsSpendingMutation()

  useEffect(() => {
    setAccountState(accounts)
  }, [accounts])

  if (accountsFetching) {
    return <Preloader />
  }

  return (
    <div className='spend-from-modal-wrapper'>
      <div className='spend-from-modal-main'>
        <div className='spend-from-header'>
          <CoreCheckbox
            type='check'
            checked={allAccounts}
            value={'all-accounts-checkbox'}
            onClick={(val) =>
              updateAllAccountSpending({ prepaidCardId: prepaidCardId, isAllAccountsSpendingEnabled: val })
                .unwrap()
                .then(() => setAllAccounts(val))
            }
            label={tr('FRONTEND.CARDS.SETTINGS.SPEND_FROM.MODAL.ALL_ACCOUNTS', 'All accounts')}
            disabled={allAccountsSpendingFetching}
          />
        </div>
        <div className='spend-from-account-list'>
          {
            accountState?.map(el => {
              return (
                <div key={el?.accountId} className='spend-from-account-element'>
                  <div className='currency-account-left-section'>
                    <CurrencyIcon circle currency={el?.currencyCode} size='3.4rem' />
                    <div>
                      <p className='account-name'>{el?.name}</p>
                      <p className='account-balance'>
                        <span className='balance-label'>
                          {tr('FRONTEND.CARDS.SETTINGS.SPEND_FROM.MODAL.BALANCE', 'Balance') + ': '}
                        </span>
                        {formatMoney(el?.balance, el?.currencyCode)}
                      </p>
                    </div>
                  </div>
                  <SwitchInput
                    checked={allAccounts || el?.cardSpendable}
                    disabled={allAccounts || addAccountFetching || removeAccountFetching}
                    onChange={(e) => {
                      const value = e.target.checked
                      if (!!value) {
                        removeAccountExclusion({ prepaidCardId, accountId: el?.accountId }).unwrap().then(() => {
                          setAccountState(cur => {
                            const curState = JSON.parse(JSON.stringify(cur))
                            const found = curState.find(acc => acc.accountId == el?.accountId)
                            found.cardSpendable = value
                            return curState
                          })
                        })
                      } else {
                        addAccountExclusion({ prepaidCardId, accountId: el?.accountId }).unwrap().then(() => {
                          setAccountState(cur => {
                            const curState = JSON.parse(JSON.stringify(cur))
                            const found = curState.find(acc => acc.accountId == el?.accountId)
                            found.cardSpendable = value
                            return curState
                          })
                        })
                      }
                    }} />
                </div>
              )
            })
          }
        </div>
      </div>
      <ErrorDisplay error={[accountsError, addAccountError, removeAccountError, allAccountsSpendingError]} />
    </div>
  )
}

export default SpendFromSelectedAccounts
