import { configuration, initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const cookieDomain = window.location.hostname.substring(window.location.hostname.indexOf('.'))
const domain = cookieDomain.substring(1)

const settings = {
  cookieDomain: configuration.isLocal() ? null : cookieDomain,
  customerServiceUrl: `mailto:support@${domain}`,
  landingPageUrl:
    configuration.isDevelopment() || configuration.isSandbox() ? `https://sandbox.${domain}` : `https://${domain}`,
  languages: ['en', 'de', 'es', 'fr', 'it', 'no', 'pl', 'pt', 'ja'],
  loginPageVideoUrl: 'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/welcome-video.mp4',
  verifyPageVideoUrl: 'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/verify-video.mp4',
  missingCardVideoUrl:
    'https://videos-public-mmfintech.s3.eu-central-1.amazonaws.com/jetonapp/jeton-card-animaiton.mp4',
  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024 * 10,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10,
  encryptPassword: '53w#7p&WP,RZ~)M55@_U',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=com.jeton.app&pli=1',
  appStoreUrl: 'https://apps.apple.com/us/app/jeton/id6499320378',
  cardsUrl: `https://cards${
    configuration.isDevelopment() ? '-dev' : configuration.isSandbox() ? '-sandbox' : ''
  }.jeton.com/` //http://localhost:3001/
}

export default settings
