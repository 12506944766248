import { Suspense, useEffect, useRef, useState } from 'react'
import { CookiesProvider } from 'react-cookie'
import { ErrorBoundary } from 'react-error-boundary'
import { withTranslation } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import { useDispatch } from 'react-redux'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import ContentWrapper from '@components/ContentWrapper'
import CoreButton from '@components/CoreButton'
import CoreMenu from '@components/CoreMenu'
import CoreToaster from '@components/CoreToaster'
import Header from '@components/Header'
import Modal from '@components/ModalDialog'
import { Otp, prepareTitle } from '@components/Otp'
import { RollingBanner } from '@components/RollingBanner'
import SidebarRight from '@components/SidebarRight'
import { coreMenuItems, rollingBankerSettings } from '@constants'
import ErrorIcon from '@images/icons/error.svg?react'
import '@scss/app/app.scss'
import settings from '@settings'
import Error404 from '@views/static/Error404'
import { ThemeProvider } from 'styled-components'

import {
  paths,
  selectCurrentUserStatus,
  useAppInitializerQry,
  useAppSelector,
  useAuthQry,
  useCardAuth,
  useLazyErrorLoggingQuery
} from 'mmfintech-backend-api'
import { GlobalContextProvider, OtpContext, isValidObject, tr } from 'mmfintech-commons'
import { LoginStatusEnum } from 'mmfintech-commons-types'
import { Preloader } from 'mmfintech-portal-commons'

import { nonProtectedRoutes, routes } from './routes'
import theme from './theme'
import { PrivateRoute, PublicRoute, statusPaths } from './utils'
import cn from 'classnames'
import IframeOtp from '@components/IframeOtp'

function AppInner() {
  useAppInitializerQry(settings.languages)

  const { isAuthFetching, authSucceeded } = useAuthQry()
  const { fetchCardAuthToken } = useCardAuth()

  const userStatus = useAppSelector(selectCurrentUserStatus)
  const queryChallenge = useAppSelector(state => state.challenge)
  const appContainerRef = useRef(null)
  const history = useHistory()

  const dispatch = useDispatch()
  const location = useLocation()

  const [modalContent, setModalContent] = useState(null)
  const [modalHeader, setModalHeader] = useState(null)
  const [modalOptions, setModalOptions] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [sidebarInnerRightContent, setSidebarInnerRightContent] = useState(null)
  const [sidebarInnerRightOptions, setSidebarInnerRightOptions] = useState(null)
  const [sidebarInnerRightVisible, setSidebarInnerRightVisible] = useState(false)
  const [sidebarRightContent, setSidebarRightContent] = useState(null)
  const [sidebarRightOptions, setSidebarRightOptions] = useState(null)
  const [sidebarRightVisible, setSidebarRightVisible] = useState(false)
  const [otpOnSuccess, setOtpOnSuccess] = useState(null)
  const [otpOnError, setOtpOnError] = useState(null)
  const [showIframeAsOverlay, setShowIframeAsOverlay] = useState<boolean>(false)
  const [iframeMessage, setIframeMessage] = useState(null)

  const { twoFactorType } = queryChallenge || {}

  const modalHide = () => {
    setModalVisible(false)
    setModalContent(null)
    setModalOptions(null)
    setModalHeader(null)
  }
  const modalShow = ({ options, content, header }) => {
    setModalContent(content)
    setModalOptions(options)
    setModalHeader(header)
    setModalVisible(true)
  }

  const sidebarInnerRightHide = () => {
    setSidebarInnerRightVisible(false)
    setSidebarInnerRightContent(null)
    setSidebarInnerRightOptions(null)
  }

  const sidebarInnerRightShow = ({ options, content }) => {
    setSidebarInnerRightContent(content)
    setSidebarInnerRightOptions(options)
    setSidebarInnerRightVisible(true)
  }

  const sidebarRightHide = () => {
    setSidebarRightVisible(false)
    setSidebarRightContent(null)
    setSidebarRightOptions(null)
  }

  const sidebarRightShow = ({ options, content }) => {
    setSidebarRightContent(content)
    setSidebarRightOptions(options)
    setSidebarRightVisible(true)
  }

  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const globalContext = {
    modalHide,
    modalShow,
    sidebarRightHide,
    sidebarRightShow,
    sidebarInnerRightHide,
    sidebarInnerRightShow,
    iframeMessage,
    setIframeMessage
  }

  useEffect(() => {
    isValidObject(queryChallenge) && queryChallenge?.hasOwnProperty('challengeId')
      ? modalShow({
        header: prepareTitle({ twoFactorType, userStatus }),
        options: { closeOnClickOutside: false },
        content: <Otp />
      })
      : modalHide()
  }, [queryChallenge, dispatch])

  useEffect(() => {
    const body = document.getElementsByTagName('body').item(0)
    if (body) {
      if (sidebarRightVisible) {
        window && window.scrollTo(0, 0)
        body.classList.add('menu-opened')
      } else {
        body.classList.remove('menu-opened')
      }
    }
  }, [sidebarRightVisible])

  useEffect(() => {
    window.addEventListener('storage', e => {
      if (e.key === 'jeton-id' && e.newValue !== e.oldValue) {
        history.go(0)
      }
    })
    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
    const eventer = window[eventMethod]
    const messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message'
    const allowedOrigins = [
      'http://localhost:3001',
      'https://cards-dev.jeton.com',
      'https://cards-sandbox.jeton.com',
      'https://cards.jeton.com'
    ]
    eventer(
      messageEvent,
      function (e) {
        if (allowedOrigins.includes(e?.origin) && appContainerRef.current) {
          const key = e.message ? 'message' : 'data'
          const data = e[key]
          switch (data.type) {
            case 'IFRAME_SIZE':
              const iframeElement = appContainerRef.current.querySelector('.modal-wrapper iframe')
                || appContainerRef.current.querySelector('iframe')
              iframeElement.style.height = data?.value + 'px'
              break
            case 'OPEN_OVERLAY':
              setShowIframeAsOverlay(true)
              break
            case 'HIDE_OVERLAY':
              setShowIframeAsOverlay(false)
              break
            case 'DETAILS_LOADED':
              setIframeMessage('DETAILS_LOADED')
              break
            case 'DETAILS_ERROR':
              setIframeMessage('CARD_DETAILS_ERROR')
              break
            case 'OPEN_IFRAME_MODAL':
              setIframeMessage('OPEN_IFRAME_MODAL')
              modalShow({
                content: <IframeOtp cardId={data?.value} />,
                header: tr('FRONTEND.MODAL.CONFIRM_OPERATION', 'Confirm operation'),
                options: { closeOnClickOutside: false }
              })
              break

            case 'CHALLENGE_SOLVED':
              setIframeMessage('REVEAL_DETAILS')
              modalHide()
              break;
          }
        }
      },
      false
    )
  }, [])

  useEffect(() => {
    if (authSucceeded) {
      fetchCardAuthToken()
    }
  }, [authSucceeded])

  const showBanner = () => {
    const dateNow = new Date()
    return (
      rollingBankerSettings.startDate.getTime() < dateNow.getTime() &&
      rollingBankerSettings.expireDate.getTime() > dateNow.getTime()
    )
  }

  return isAuthFetching ? (
    <Preloader />
  ) : (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <GlobalContextProvider context={globalContext}>
          <OtpContext.Provider
            value={{
              otpOnSuccess,
              setOtpOnSuccess,
              otpOnError,
              setOtpOnError
            }}>
            <SkeletonTheme baseColor='#e0e0e0' highlightColor='#ccc'>
              <div className={cn('app-container', { 'overlay-state': !!showIframeAsOverlay })} ref={appContainerRef}>
                {isLoggedIn() && !nonProtectedRoutes.find(route => route.path == location.pathname) && (
                  <CoreMenu items={coreMenuItems} />
                )}
                <div className='main-content'>
                  {isLoggedIn() && !nonProtectedRoutes.find(route => route.path == location.pathname) && (
                    <div className='header'>
                      <Header />
                    </div>
                  )}
                  <SidebarRight
                    content={sidebarRightContent}
                    options={sidebarRightOptions}
                    visible={sidebarRightVisible}
                    onClose={sidebarRightHide}
                  />
                  {isLoggedIn() && location.pathname === paths.dashboard() && showBanner() && <RollingBanner />}
                  <ContentWrapper
                    sideBarInner={{
                      content: sidebarInnerRightContent,
                      options: sidebarInnerRightOptions,
                      visible: sidebarInnerRightVisible,
                      onClose: sidebarInnerRightHide
                    }}>
                    <div className='content-container'>
                      <Switch>
                        {nonProtectedRoutes.map(({ path, Component, redirect }, index) => {
                          if (redirect) {
                            return (
                              <PrivateRoute
                                key={index}
                                path={path}
                                exact
                                component={Component}
                                redirect={redirect}
                                invalidSessionRedirect={statusPaths(userStatus)}
                              />
                            )
                          }
                          return <PublicRoute key={index} path={path} exact component={Component} />
                        })}
                        {routes.map(({ path, PrivateComponent, redirect }, index) => (
                          <PrivateRoute
                            key={index}
                            path={path}
                            exact
                            component={PrivateComponent}
                            redirect={redirect}
                            invalidSessionRedirect={statusPaths(userStatus)}
                          />
                        ))}
                        <Route path='*' component={Error404} />
                      </Switch>

                      <Modal
                        header={modalHeader}
                        content={modalContent}
                        options={modalOptions}
                        visible={modalVisible}
                        onClose={modalHide}
                      />
                    </div>
                  </ContentWrapper>
                </div>
              </div>
              <CoreToaster />
            </SkeletonTheme>
          </OtpContext.Provider>
        </GlobalContextProvider>
      </ThemeProvider>
    </CookiesProvider>
  )
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div className='app-alert-wrapper'>
      <div className='app-alert'>
        <ErrorIcon />
        <p data-test='app-alert-unexpected-error'>An unexpected error occurred</p>
        <CoreButton
          fullWidth
          data-test='app-alert-unexpected-error-back-to-home-button'
          variation='primary'
          size='normal'
          style={{ maxWidth: '30rem' }}
          onClick={resetErrorBoundary}
          title='Go back to the home page'
        />
      </div>
    </div>
  )
}

const ThisApp = withTranslation()(AppInner)

function App() {
  const [sendError] = useLazyErrorLoggingQuery()
  return (
    <Suspense
      fallback={
        <div className='app-preload-wrapper'>
          <Preloader />
        </div>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(err, { componentStack }) => sendError({ level: 'ERROR', componentStack, message: err.toString() })}
        onReset={() => {
          window.location.replace('/')
        }}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App
