import { Dispatch, useCallback, useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'

import CoreButton from '@components/CoreButton'
import VisaLogo from '@images/icons/cardTypes/visa-pure-icon.svg?react'
// import CloudIcon from '@images/icons/cloud.svg?react'
import CopyIcon from '@images/icons/copy-icon.svg?react'
import MastercardLogo from '@images/icons/mastercard-logo.svg?react'
import UnionPayLogo from '@images/icons/unionPay_logo.svg?react'
import JetonLogo from '@images/logo.svg?react'
import cn from 'classnames'
import axios from 'axios'

import { copyTextToClipboard, GlobalContext, tr } from 'mmfintech-commons'

import './rotatingCard.scss'
import { endpoints, prepareBackendUrl, useGetMerchantQuery } from 'mmfintech-backend-api'
import settings from '@settings'

enum CardBrandEnum {
  MASTERCARD = 'MASTERCARD',
  UNIONPAY = 'UNIONPAY',
  VISA = 'VISA'
}

const CardLogo = (cardBrand: string) => {
  switch (cardBrand) {
    case CardBrandEnum.MASTERCARD:
      return <MastercardLogo className='mastercard-logo' />
    case CardBrandEnum.UNIONPAY:
      return <UnionPayLogo className='unionpay-logo' />
    case CardBrandEnum.VISA:
      return <VisaLogo className='visa-logo' />
    default:
      return <></>
  }
}

interface RotatingCardProps {
  cardNumber?: number | string
  cardId?: number | string
  lastFourDigits?: number | string
  validity?: string
  cvv?: number
  flip?: { detailsShown: boolean; actionCalled: number }
  enableRotation?: boolean
  setVisibleSide?: (visibleSide: 'front' | 'back') => void
  visibleSide?: 'front' | 'back'
  cardType?: 'virtual' | 'physical'
  isFrozen?: boolean
  hasShadow?: boolean
  cardBrand?: string
  cardHolderName?: string
  cardDetails?: any
  setCardFlip?: Dispatch<any>
}

const RotatingCard = ({
  cardNumber,
  lastFourDigits,
  validity,
  cvv,
  flip,
  setVisibleSide,
  enableRotation = true,
  visibleSide,
  cardType,
  isFrozen,
  hasShadow = false,
  cardBrand,
  cardHolderName,
  cardId,
  cardDetails,
  setCardFlip
}: RotatingCardProps) => {
  const [prevPosition, setPrevPosition] = useState(0)
  const [calcPosition, setCalcPosition] = useState(0)
  const sensitivity = 3 //arbitrary value that handles the speed of the rotation

  const sectionRef = useRef<HTMLDivElement>()
  const cardRef = useRef<HTMLDivElement>()

  const { data: merchant } = useGetMerchantQuery()
  const { capabilities } = merchant || {}
  const { enableCardIFrames } = capabilities as any || {}
  const frameRef = useRef<HTMLIFrameElement>()
  const { iframeMessage } = useContext(GlobalContext) as any


  const handleRotation = useCallback(e => {
    e.preventDefault()
    let initialCoordinates = e.clientX
    const body = document.body

    const rotate = e => {
      const currentCoordinates = e.clientX
      setCalcPosition(((currentCoordinates - initialCoordinates) / sensitivity) % 360)

      setPrevPosition(value => {
        return (value + (currentCoordinates - initialCoordinates) / sensitivity) % 360
      })

      body.style.cursor = 'grabbing'
      cardRef.current.style.transition = 'unset'

      initialCoordinates = currentCoordinates
    }

    sectionRef.current.addEventListener('mousemove', rotate)

    const cancelRotation = () => {
      sectionRef.current.removeEventListener('mousemove', rotate)
      cardRef.current.style.transition = 'all 500ms cubic-bezier(0.6, 0.4, 0.3, 1.7)'
      body.style.cursor = 'default'
    }

    sectionRef.current.addEventListener('mouseup', cancelRotation)
    sectionRef.current.addEventListener('mouseleave', cancelRotation)
  }, [])

  useEffect(() => {
    const rotationAngle = calcPosition + prevPosition
    if (flip?.detailsShown === true) {
      if (rotationAngle < -180) {
        return setPrevPosition(-180)
      }
      setPrevPosition(180)
    } else {
      setPrevPosition(0)
    }
    setCalcPosition(0)
  }, [flip])

  useEffect(() => {
    const rotationAngle = calcPosition + prevPosition
    if (rotationAngle > 360 || rotationAngle < -360) return
    if (
      (rotationAngle < 90 && rotationAngle > -90) ||
      (rotationAngle < -270 && rotationAngle > -360) ||
      (rotationAngle > 270 && rotationAngle < 360)
    ) {
      setVisibleSide('front')
    } else {
      setVisibleSide('back')
      setCardFlip({
        detailsShown: true,
        actionCalled: Date.now()
      })
    }
  }, [prevPosition, calcPosition])

  useEffect(() => {
    if (sectionRef && sectionRef.current) {
      sectionRef.current.removeEventListener('mousedown', handleRotation)
      if (enableRotation) {
        sectionRef.current.addEventListener('mousedown', handleRotation)
      }
    }
  }, [enableRotation])

  useEffect(() => {
    if (cardDetails == '_REVEAL' && enableCardIFrames && frameRef.current) {
      frameRef.current.contentWindow.postMessage({ type: 'REVEAL_DETAILS', value: cardId }, '*')
    }
  }, [cardDetails])

  useEffect(() => {
    if (iframeMessage === 'REVEAL_DETAILS') {
      frameRef.current.contentWindow.postMessage({ type: 'REVEAL_DETAILS', value: cardId }, '*')
    }
  }, [iframeMessage])


  return (
    <div className='rotating-card-section' ref={sectionRef}>
      <div className='rotating-card-wrapper'>
        <div
          className={cn('rotating-card', { physical: cardType === 'physical', frozen: isFrozen })}
          ref={cardRef}
          style={{ transform: `rotateY(${calcPosition + prevPosition}deg)` }}>
          <div className={cn('rotating-card-frozen')}></div>
          {capabilities && enableCardIFrames ?
            <div className='overlay-wrapper'>
              <iframe
                src={`${settings.cardsUrl}view?type=${cardType}`}
                style={{ height: '19rem' }}
                allow="clipboard-read; clipboard-write"
                ref={frameRef}
                onLoad={async (ev) => {
                  const frame = ev?.target as any;

                  const tokenResponse = await axios({
                    url: prepareBackendUrl(endpoints.prepaidCards.getIsolatedCardToken()),
                    method: 'GET',
                    withCredentials: true
                  })
                  // TODO: Replace with the real cards domain on release https://cards-dev.jeton.com/
                  frame.contentWindow.postMessage({ type: 'HANDSHAKE', value: tokenResponse?.data }, '*');
                }}
              />
            </div> :

            <div className='card-front'>
              <JetonLogo width={80} height={20} />
              {/* <CloudIcon className='cloud-icon' /> */}
              <div className='rotating-card-front-top-data'>
                <div className='rotating-card-holder-name'>{cardHolderName}</div>
                {cardHolderName && (
                  <CoreButton
                    style={visibleSide == 'front' && { cursor: 'default' }}
                    className='rotating-card-copy-button'
                    LeftIcon={<CopyIcon />}
                    onClick={e => {
                      e.stopPropagation()
                      if (visibleSide == 'back') {
                        copyTextToClipboard(
                          cardHolderName.toString(),
                          toast.success(
                            tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                            {
                              position: 'top-right'
                            }
                          )
                        )
                      }
                    }}
                  />
                )}
              </div>
              <div className='rotating-card-front-top-data'>
                <div className='rotating-card-number'>
                  {cardNumber &&
                    cardNumber
                      .toString()
                      .match(/.{1,4}/g)
                      .join(' ')}
                </div>
                {cardNumber && (
                  <CoreButton
                    style={visibleSide == 'front' && { cursor: 'default' }}
                    className='rotating-card-copy-button'
                    LeftIcon={<CopyIcon />}
                    onClick={e => {
                      e.stopPropagation()
                      if (visibleSide == 'back') {
                        copyTextToClipboard(
                          cardNumber.toString(),
                          toast.success(
                            tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                            {
                              position: 'top-right'
                            }
                          )
                        )
                      }
                    }}
                  />
                )}
              </div>
              <div className='rotating-card-front-bottom-data'>
                <div className='rotating-card-validity'>
                  <label className='rotating-card-validity-label'>VALID THRU</label>
                  {validity}
                </div>
                <div className='rotating-card-cvv'>
                  <label className='rotating-card-cvv-label'>CVC</label>
                  {cvv}
                </div>
                {cvv && (
                  <CoreButton
                    style={visibleSide == 'front' && { cursor: 'default' }}
                    className='rotating-card-copy-button'
                    LeftIcon={<CopyIcon />}
                    onClick={e => {
                      e.stopPropagation()
                      if (visibleSide == 'back') {
                        copyTextToClipboard(
                          cvv.toString(),
                          toast.success(
                            tr('FRONTEND.CARDS.ROTATING_CARD.CLIPBOARD_MESSAGE', 'Text copied to clipboard'),
                            {
                              position: 'top-right'
                            }
                          )
                        )
                      }
                    }}
                  />
                )}
              </div>
            </div>
          }

          <div className='card-left-side'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-fill'></div>
          <div className='card-right-side'></div>
          <div className='card-back'>
            <JetonLogo className='jeton-logo' width={80} height={20} />
            {/* <CloudIcon className='cloud-icon' /> */}
            {CardLogo(cardBrand)}
            <div className='card-back-number'>{lastFourDigits && '* ' + lastFourDigits}</div>
          </div>
          {hasShadow && <div className='rotating-card-shadow'></div>}
        </div>
      </div>
    </div>
  )
}

export default RotatingCard
