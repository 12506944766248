import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { useSetCardPinMutation, useSetCardPinInitiateMutation } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, checkFinalValue, tr, translateError, useFormValues } from 'mmfintech-commons'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'

import { ErrorDisplay, Preloader } from 'mmfintech-portal-commons'

import '../cardSettings.scss'

interface EditCardPinProps {
  prepaidCardId?: string
  isSet?: boolean
  preselectedPin?: string
  referenceId?: string
}

const EditCardPin = ({ prepaidCardId, isSet = false, preselectedPin, referenceId }: EditCardPinProps) => {
  const { setOtpOnSuccess } = useContext(OtpContext)
  const { modalHide, modalShow } = useContext(GlobalContext)

  const [validationError, setValidationError] = useState<string>('')

  const [initiateSetCardPin, { error: initiateError, isLoading: initiateLoading }] = useSetCardPinInitiateMutation()
  const [setCardPin, { error: setPinError, isLoading: setPinLoading }] = useSetCardPinMutation()

  const formValues = useFormValues({
    pin: { required: true },
    confirmPin: { required: true }
  })

  const handlePinChange = async () => {
    const pinCheck = checkFinalValue(formValues.getValue('pin'), { validation: 'numeric', minLength: 4, maxLength: 4 })
    if (!pinCheck.valid) {
      setValidationError(translateError(pinCheck))
      return
    }

    if (formValues.getValue('pin') !== formValues.getValue('confirmPin')) {
      setValidationError(tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.ERROR', 'PIN numbers do not match'))
      return
    }

    setOtpOnSuccess(() => (response: any) => {
      setTimeout(() => {
        modalShow({
          options: { closeOnClickOutside: true },
          header: tr('FRONTEND.CARDS.SETTINGS.MODAL.CHANGE_PIN.TITLE', 'Change PIN'),
          content: (
            <EditCardPin
              prepaidCardId={prepaidCardId}
              isSet={true}
              preselectedPin={formValues.getValue('pin')}
              referenceId={response?.referenceId}
            />
          )
        })
      }, 100)
    })

    try {
      const result = await initiateSetCardPin({ prepaidCardId, pin: formValues.getValue('pin') }).unwrap()

      if (!result) {
        toast.success(tr('FRONTEND.CARDS.SETTINGS.SET_PIN.TOAST', 'Card pin set successfully'))
        setOtpOnSuccess(null)
        modalHide()
      }
    } catch {}
  }

  useEffect(() => {
    if (preselectedPin && referenceId) {
      formValues.setValue('pin', preselectedPin)
      formValues.setValue('confirmPin', preselectedPin)
      setCardPin({ pin: preselectedPin, referenceId })
        .unwrap()
        .then(() => {
          toast.success(tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.TOAST', 'Card pin changed successfully'))
          modalHide()
        })
    }
  }, [preselectedPin, referenceId])

  return (
    <div className='change-card-pin-wrapper'>
      {!setPinLoading ? (
        <div className='change-card-pin-form'>
          {/* <div className='change-card-pin-title'>
            {isSet
              ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.TITLE', 'Add new PIN')
              : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.TITLE', 'Set card PIN')}
          </div> */}
          <CoreInput
            type='password'
            label={
              isSet
                ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.NEW_PIN', 'New PIN')
                : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.LABEL', 'Card PIN')
            }
            {...formValues.registerInput('pin', () => setValidationError(''))}
            autoComplete='new-password'
            disabled={!!preselectedPin}
          />
          <CoreInput
            type='password'
            label={tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.CONFIRM_PIN', 'Confirm PIN')}
            {...formValues.registerInput('confirmPin', () => setValidationError(''))}
            autoComplete='new-password'
            disabled={!!preselectedPin}
          />
        </div>
      ) : (
        <div className='change-card-pin-loader'>
          <Preloader />
        </div>
      )}

      <ErrorDisplay
        error={[initiateError?.cause && initiateError?.cause[0]?.cause, initiateError, validationError, setPinError]}
      />

      {!setPinLoading && (
        <CoreButton
          fullWidth
          type='button'
          variation='primary'
          size='normal'
          title={
            isSet
              ? tr('FRONTEND.CARDS.SETTINGS.CHANGE_PIN.MODAL.REPLACE_BUTTON', 'Replace')
              : tr('FRONTEND.CARDS.SETTINGS.SET_PIN.MODAL.SET_BUTTON', 'Set')
          }
          isLoading={initiateLoading}
          onClick={handlePinChange}
        />
      )}
    </div>
  )
}

export default EditCardPin
