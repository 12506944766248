import { useContext } from 'react'

import CoreStatusView from '@components/CoreStatusView'
// import ProfileAvatar from '@components/ProfileAvatar'
import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import PreviewIcon from '@images/icons/preview-icon.svg?react'
import TransactionDetails from '@views/transactions/TransactionDetails'
// import { TransactionTitleWithDate } from '@views/transactions/elements/TransactionTypeWithIcon'
import cn from 'classnames'
import moment from 'moment'

import { getTransactionStatusName, useCurrencies } from 'mmfintech-backend-api'
import { GlobalContext, fetchLastChunk, formatMoney, tr } from 'mmfintech-commons'
import { TransactionStatusEnum } from 'mmfintech-commons-types'
import { DataTable } from 'mmfintech-portal-commons'

import '../../../transactions/elements/styled/transactionTables.scss'
import '../../../transactions/styled/transactionsTable.scss'

function TransactionsTable({ columns, transactions }) {
  const { getCurrencyPrecision } = useCurrencies()
  const isSmall = useMatchMedia({ breakpoint: 800, prefix: 'max' })
  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'min' })
  const { modalShow } = useContext(GlobalContext)

  const handleDetailsClick = (transactionId: number) => {
    modalShow({
      header: tr('FRONTEND.TRANSACTIONS.DETAILS.TITLE', 'Transaction details'),
      content: <TransactionDetails isModal={true} transactionId={transactionId} />,
      options: {
        size: 'auto'
      }
    })
  }

  return (
    <div className='table-container transaction-table-container'>
      <DataTable.Table skipHover>
        <DataTable.Head>
          <DataTable.Row>
            {/* {
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.COUNTERPARTY', 'Counterparty')}
              </DataTable.HeadCell>
            } */}
            {!isSmall && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('type') && (
              <DataTable.HeadCell className='no-border table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
              </DataTable.HeadCell>
            )}
            {columns.isVisible('status') && (
              <DataTable.HeadCell className='thin no-border  table-header-cell'>
                {tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
              </DataTable.HeadCell>
            )}
            <DataTable.HeadCell
              style={{
                textAlign: 'right'
              }}
              className='no-border table-header-cell'>
              {tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
            </DataTable.HeadCell>
            <DataTable.HeadCell className='buttons no-border' />
          </DataTable.Row>
        </DataTable.Head>

        <DataTable.Body>
          {transactions.map((data: any, index: number) => {
            const {
              id,
              amount,
              status,
              reqTime,
              currency,
              direction,
              trnTypeLocalizationKey /* , emoneyTransferDetails */
            } = data
            // const { counterpartyName } = emoneyTransferDetails || {}
            const transactionType = tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))
            const formattedType = transactionType.toLowerCase().replaceAll('_', ' ')
            return (
              <DataTable.Row
                key={`data-${index}`}
                data-test='table-row'
                onClick={() => {
                  if (!isMobile) {
                    handleDetailsClick(id)
                  }
                }}>
                {/* {
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.COUNTERPARTY', 'Counterparty')}
                    className='no-wrap no-border table-cell'
                    data-test='counterparty'>
                    {!isSmall ? (
                      <div className='counterparty-container'>
                        <ProfileAvatar name={counterpartyName} />
                        <p>{counterpartyName}</p>
                      </div>
                    ) : (
                      <TransactionTitleWithDate
                        title={counterpartyName}
                        date={moment(new Date(reqTime)).format('DD/MM/YYYY H:mm')}
                      />
                    )}
                  </DataTable.Cell>
                } */}
                {!isSmall && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                    className='no-wrap no-border table-cell'
                    data-test='date'>
                    {moment(new Date(reqTime)).format('DD/MM/YYYY H:mm')}
                  </DataTable.Cell>
                )}
                {columns.isVisible('type') && (
                  <DataTable.Cell
                    className='no-border table-cell type-container'
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}
                    data-test='type'>
                    {formattedType}
                  </DataTable.Cell>
                )}
                {columns.isVisible('status') && (
                  <DataTable.Cell
                    caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                    className='status-cell no-border table-cell'
                    data-test='status'>
                    <CoreStatusView status={status as TransactionStatusEnum} />
                  </DataTable.Cell>
                )}
                <DataTable.Cell
                  className='no-border'
                  style={{
                    textAlign: 'right'
                  }}
                  caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                  data-test='amount'>
                  <div className={cn('amount-container', { cancelled: status === 'CANCELLED' })} translate='no'>
                    <span>
                      {formatMoney(
                        amount * (direction === 'WITHDRAW' ? -1 : 1),
                        currency,
                        getCurrencyPrecision(currency)
                      )}
                    </span>
                    {!isMobile && (
                      <CoreStatusView status={getTransactionStatusName(status) as TransactionStatusEnum} fade={true} />
                    )}
                  </div>
                </DataTable.Cell>
                {isMobile && (
                  <DataTable.Cell
                    onClick={() => handleDetailsClick(id)}
                    className='no-border transaction-details-button'
                    table-cell>
                    <PreviewIcon />
                  </DataTable.Cell>
                )}
              </DataTable.Row>
            )
          })}
        </DataTable.Body>
      </DataTable.Table>
    </div>
  )
}

export default TransactionsTable
