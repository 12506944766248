import { ReactElement } from 'react'
import ReactTooltip from 'react-tooltip'

import cn from 'classnames'
import type * as CSS from 'csstype'

import colors from '../../scss/variables/_colors.module.scss'
import buttonConfiguration, { buttonSize, buttonValidation } from './buttonConfig'
import './coreButton.scss'

interface IConfig {
  buttonClass: string
  collapsedClass: string
  loadButtonClass: string
}

interface IConfigurationOptions {
  variation: buttonValidation
  size?: buttonSize
}

const classButtonConfig = ({ variation, size }: IConfigurationOptions): IConfig => {
  return buttonConfiguration[variation][size] as IConfig
}

interface IPrimaryButtonProps {
  title?: string | ReactElement
  variation?: buttonValidation
  size?: buttonSize
  disabled?: boolean
  onClick?: (e?: any) => void | Promise<void>
  collapsed?: boolean
  LeftIcon?: ReactElement
  RightIcon?: ReactElement
  CollapsedIcon?: ReactElement
  isLoading?: boolean
  fullWidth?: boolean
  width?: string
  style?: CSS.Properties
  className?: string
  type?: 'button' | 'submit' | 'reset'
  tooltip?: string
  ['data-test']?: string
}

const sizeConfig = {
  small: '3px',
  normal: '7px',
  large: '9px'
}

interface ILoadingButton {
  loadButtonClass: string
  size?: buttonSize
  variation: buttonValidation
  style: CSS.Properties
}

export const LoadingButton = ({ loadButtonClass, size, variation, style, ...props }: ILoadingButton) => {
  const color = variation === 'primary' ? 'white' : colors.orange500
  return (
    <button className={loadButtonClass} style={style} {...props}>
      <div className='loading-button'>
        <div>
          <span style={{ margin: sizeConfig[size], backgroundColor: color }} className='dot3'></span>
          <span style={{ margin: sizeConfig[size], backgroundColor: color }} className='dot4'></span>
        </div>
        <div>
          <span style={{ margin: sizeConfig[size], backgroundColor: color }} className='dot1'></span>
          <span style={{ margin: sizeConfig[size], backgroundColor: color }} className='dot2'></span>
        </div>
      </div>
    </button>
  )
}

const CoreButton = ({
  title,
  disabled,
  onClick,
  collapsed,
  isLoading,
  LeftIcon,
  RightIcon,
  CollapsedIcon,
  variation = 'primary',
  size = 'small',
  fullWidth,
  width,
  style,
  className,
  tooltip,
  ...props
}: IPrimaryButtonProps) => {
  const config: IConfig = classButtonConfig({ variation, size, ...props })

  if (isLoading) {
    return (
      <LoadingButton
        loadButtonClass={config.loadButtonClass}
        size={size}
        variation={variation}
        style={{ ...style, width: fullWidth ? '100%' : width }}
        {...props}
      />
    )
  }

  return !collapsed ? (
    <button
      data-test='core-button-base'
      onClick={onClick}
      style={{ ...style, width: fullWidth ? '100%' : width }}
      disabled={disabled}
      {...props}
      className={cn(className, config.buttonClass)}>
      {LeftIcon}
      {title && typeof title === 'string' && (
        <span data-test={`core-button-title-${title}`} className='body-medium'>
          {title}
        </span>
      )}
      {title && typeof title === 'object' && title}
      {RightIcon}
    </button>
  ) : (
    <button
      data-test='core-button-base'
      onClick={onClick}
      style={{ ...style }}
      disabled={disabled}
      data-for={`custom-${tooltip}`}
      data-tip={tooltip}
      className={cn(className, config.collapsedClass)}>
      {CollapsedIcon || <span className='icon'>+</span>}
      <ReactTooltip
        delayShow={200}
        id={`custom-${tooltip}`}
        className='custom-tool-tip-component'
        textColor='black'
        backgroundColor='white'
        effect='solid'
      />
    </button>
  )
}

export default CoreButton
