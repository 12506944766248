import { useContext, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import CoreButton from '@components/CoreButton'
import ATMWithdrawalsIcon from '@images/icons/atm-withdrawals.svg?react'
import ContactlessOpsIcon from '@images/icons/contactless-ops.svg?react'
import ECommerceOpsIcon from '@images/icons/ecommerce-ops.svg?react'
import POSOperationsIcon from '@images/icons/pos-operations.svg?react'

import { responseDoesNotContainsChallenge, useChangeCardOperationsMutation } from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, tr } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import CardSettingsItem from '../CardSettingsItem'

interface AdvanceCardOptionsProps {
  supportedOperations?: any
  prepaidCardId?: string
}

const AdvancedCardOptions = ({ supportedOperations, prepaidCardId }: AdvanceCardOptionsProps) => {
  const { modalHide } = useContext(GlobalContext)
  const { setOtpOnSuccess } = useContext(OtpContext)

  const [cardOptions, setCardOptions] = useState({
    supportsAtmWithdrawals: true,
    supportsContactless: true,
    supportsECommerce: true,
    supportsPhysicalPos: true
  })

  const [changeOptions, { isLoading, error }] = useChangeCardOperationsMutation()

  const setOption = (optionKey: string, optionValue: Boolean) => {
    setCardOptions(currentState => {
      return { ...currentState, [optionKey]: !!optionValue }
    })
  }

  const handleSubmit = async () => {
    setOtpOnSuccess(() => (response: any) => {
      if (response && response.externalCardId) {
        toast.success(
          tr('FRONTEND.CARDS.SETTINGS.ADVANCE_OPTIONS.MODAL.SUCCESS', 'Card options changed successfully!'),
          { duration: 3_000 }
        )
      }
    })

    const settingsChanges = {}
    Object.keys(supportedOperations).forEach(key => {
      if (cardOptions[key] != supportedOperations[key]) {
        settingsChanges[key] = cardOptions[key]
      }
    })

    try {
      const result = await changeOptions({
        prepaidCardId: prepaidCardId,
        ...settingsChanges
      }).unwrap()

      if (responseDoesNotContainsChallenge(result)) {
        toast.success(
          tr('FRONTEND.CARDS.SETTINGS.ADVANCE_OPTIONS.MODAL.SUCCESS', 'Card options changed successfully!'),
          { duration: 3_000 }
        )
        modalHide()
      }
    } catch (err) {
      if (supportedOperations) {
        Object.keys(supportedOperations).forEach(key => {
          setCardOptions(val => {
            return {
              ...val,
              [key]: supportedOperations[key]
            }
          })
        })
      }
    }
  }

  useEffect(() => {
    if (supportedOperations) {
      Object.keys(supportedOperations).forEach(key => {
        setCardOptions(val => {
          return {
            ...val,
            [key]: supportedOperations[key]
          }
        })
      })
    }
  }, [supportedOperations])

  return (
    <div className='advanced-card-options-wrapper'>
      <div className='advanced-card-options-buttons'>
        <CardSettingsItem
          key={'atmWithdrawals'}
          icon={<ATMWithdrawalsIcon />}
          title={tr('FRONTEND.CARDS.SETTINGS.ATM_WITHDRAWALS.TITLE', 'ATM Withdrawals')}
          subtitle={tr('FRONTEND.CARDS.SETTINGS.ATM_WITHDRAWALS.SUBTITLE', 'Daily withdraw limits 400 EUR')}
          toggleValue={cardOptions['supportsAtmWithdrawals']}
          toggleHandler={checked => {
            setOption('supportsAtmWithdrawals', checked)
          }}
        />
        <CardSettingsItem
          key={'eCommerceOps'}
          icon={<ECommerceOpsIcon />}
          title={tr('FRONTEND.CARDS.SETTINGS.ECOMMERCE_OPS.TITLE', 'eCommerce operations')}
          toggleValue={cardOptions['supportsECommerce']}
          toggleHandler={checked => {
            setOption('supportsECommerce', checked)
          }}
        />
        <CardSettingsItem
          key={'posOperations'}
          icon={<POSOperationsIcon />}
          title={tr('FRONTEND.CARDS.SETTINGS.POS_OPERATIONS.TITLE', 'Physical POS operations')}
          toggleValue={cardOptions['supportsPhysicalPos']}
          toggleHandler={checked => {
            setOption('supportsPhysicalPos', checked)
          }}
        />
        <CardSettingsItem
          key={'contactlessOps'}
          icon={<ContactlessOpsIcon />}
          title={tr('FRONTEND.CARDS.SETTINGS.CONTACTLESS_OPS.TITLE', 'Contactless operations')}
          toggleValue={cardOptions['supportsContactless']}
          toggleHandler={checked => {
            setOption('supportsContactless', checked)
          }}
        />
      </div>
      <ErrorDisplay error={error} />
      <div className='advanced-card-options-submit'>
        <CoreButton
          fullWidth
          size='normal'
          variation='primary'
          title={tr('FRONTEND.CARDS.SETTINGS.ADVANCED_OPTIONS.SAVE', 'Save')}
          onClick={handleSubmit}
          isLoading={isLoading}
        />
      </div>
    </div>
  )
}

export default AdvancedCardOptions
