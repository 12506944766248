import { useEffect, useState } from 'react'

import settings from '@settings'

import { breakpoints } from '@constants'
import { useMatchMedia } from '@hooks'
import { useLazyGetTransactionsQuery, useTransactionColumns } from 'mmfintech-backend-api'
import { fixDateOnly, isValidArray, isValidObject, tr, useFilter, usePagination } from 'mmfintech-commons'

import CoreInput from '@components/CoreInput'
import CoreButton from '@components/CoreButton'
import Pagination from '@components/CorePagination'
import SidebarRight from '@components/SidebarRight'
import TransactionsTable from './elements/TransactionsTable'
import TransactionsFilter from './elements/TransactionsFilter'
import NoTransactionsMessage from '@views/transactions/NoTransactionsMessage'

import { ErrorDisplay, Spinner } from 'mmfintech-portal-commons'

import FiltersIcon from '@images/icons/transactions-filter-icon.svg?react'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'
import ClearFiltersIcon from '@images/icons/transaction-refresh-filters-icon.svg?react'
import NoTransactionsImage from '@images/icons/transactions-icon.png'

import '../../transactions/styled/transactions.scss'

function CardTransactions({
  cardId,
  cardType,
  transactionLoading
}: {
  cardId: number
  cardType: 'virtual' | 'physical'
  transactionLoading: boolean
}) {
  const columns = useTransactionColumns({
    cookieDomain: settings.cookieDomain,
    enableCustomerEmail: false,
    enableFailReason: false
  })
  const [applyFilter, setApplyFilter] = useState<boolean>(false)
  const [openFilterModal, setOpenFilterModal] = useState<boolean>(false)
  const [countFilters, setCountFilters] = useState<number>(0)
  const [fetchTransactions, { transactions, transactionsError, transactionsFetching }] = useLazyGetTransactionsQuery({
    selectFromResult: ({ data, error, isFetching }) => ({
      transactions: isValidArray(data?.content) ? data.content : [],
      transactionsError: error,
      transactionsFetching: isFetching
    })
  })

  const isMobile = useMatchMedia({ breakpoint: breakpoints.md, prefix: 'max' })
  const isLarge = useMatchMedia({ breakpoint: 1380, prefix: 'min' })
  const isCollapsedButtons = useMatchMedia({ breakpoint: 1600, prefix: 'max' })

  const filters = useFilter({})
  const clearAll = () => {
    filters.reset()
    setApplyFilter(true)
  }
  const pagination = usePagination({
    rowsPerPage: 7,
    reload: async (params: any, onSuccess?: (response: any) => void) => {
      try {
        const response = await fetchTransactions(prepareFilter(params, filters)).unwrap()
        typeof onSuccess === 'function' && onSuccess(response)
      } catch {}
    }
  })

  useEffect(() => {
    if (applyFilter) {
      setCountFilters(
        Object.values(filters.filterValues).filter(
          value =>
            // value !== filters.filterValues.from &&
            // value !== filters.filterValues.to &&
            value !== filters.filterValues.accountId
        ).length
      )
      pagination.reload()
      setApplyFilter(false)
    }
  }, [applyFilter])

  useEffect(() => {
    if (
      filters.filterValues.from ||
      filters.filterValues.to ||
      filters.filterValues.accountId ||
      filters.filterValues.accountId === 0
    ) {
      setApplyFilter(true)
    }
  }, [filters.filterValues.from, filters.filterValues.to, filters.filterValues.accountId])

  useEffect(() => {
    setApplyFilter(true)
  }, [cardId])

  const prepareFilter = (params, filters) => {
    const { ...rest } = params
    return {
      ...rest,
      prepaidCardIds: [cardId],
      ...(filters
        ? {
            ...filters.prepare(),
            ...(filters.get('statuses') ? { statuses: [...filters.get('statuses')?.split(',')] } : null),
            ...(filters.get('paymentMethods') ? { paymentMethods: [filters.get('paymentMethods')] } : []),
            ...(filters.get('currency') ? { currency: filters.get('currency') } : []),
            from: fixDateOnly(filters.get('from')),
            to: fixDateOnly(filters.get('to'))
          }
        : {}),
      sort: 'reqTime-desc,id-desc'
    }
  }

  return (
    <div className='transaction-container' style={{ margin: '2.4rem 0 0 0' }}>
      <SidebarRight
        content={
          <TransactionsFilter
            isMobile={!isLarge}
            filters={filters}
            setApplyFilter={setApplyFilter}
            modalHide={() => setOpenFilterModal(false)}
            clearAll={clearAll}
          />
        }
        visible={openFilterModal}
        options={{ size: 'extra-large' }}
        onClose={() => setOpenFilterModal(false)}
      />
      <div className='transaction-section-header'>
        <div className='title'>{tr('FRONTEND.TRANSACTIONS.TITLE', 'Transactions')}</div>
        {isValidArray(transactions) && (
          <div className='buttons-container'>
            {isLarge && (
              <CoreInput
                type='date'
                name='from'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.FROM', 'From')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsStart
                rangeDates
                maxDate={filters.get('to')}
                {...filters.registerInput('from')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-from-date'
                clearable
              />
            )}
            {isLarge && (
              <CoreInput
                type='date'
                name='to'
                label={tr('FRONTEND.TRANSACTIONS.FILTER.TO', 'To')}
                LeftIcon={<CalendarIcon />}
                className='transaction-calendar-input'
                selectsEnd
                rangeDates
                minDate={filters.get('from')}
                {...filters.registerInput('to')}
                startDate={filters.get('from')}
                endDate={filters.get('to')}
                data-test='filter-to-date'
                clearable
              />
            )}
            {isValidObject(filters.prepare()) && (
              <CoreButton
                variation='tertiary'
                onClick={() => {
                  clearAll()
                }}
                collapsed={(isCollapsedButtons && isLarge) || isMobile}
                CollapsedIcon={<ClearFiltersIcon />}
                LeftIcon={<ClearFiltersIcon />}
                tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Reset filters') : ''}
                size='normal'
                title={tr('FRONTEND.TRANSACTIONS.FILTER.CLEAR_ALL_BUTTON', 'Reset filters')}
                data-test='reset-filter-button'
              />
            )}
            <CoreButton
              onClick={() => setOpenFilterModal(true)}
              variation='secondary'
              size='normal'
              className='transaction-filter-button'
              title={tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters')}
              LeftIcon={<FiltersIcon />}
              CollapsedIcon={<FiltersIcon />}
              tooltip={isCollapsedButtons ? tr('FRONTEND.TRANSACTIONS.FILTER.TITLE', 'Filters') : ''}
              collapsed={(isCollapsedButtons && isLarge) || isMobile}
              RightIcon={countFilters > 0 && <span className='transaction-filter-count-icon'>{countFilters}</span>}
            />
          </div>
        )}
      </div>
      <div className='transaction-main-row'>
        {transactionsFetching || transactionLoading ? (
          <Spinner />
        ) : transactionsError ? (
          <ErrorDisplay error={transactionsError} />
        ) : isValidArray(transactions) ? (
          <>
            <TransactionsTable columns={columns} transactions={transactions} />
            <div className='pagination-buttons-container'>
              <Pagination {...pagination.register()} pageRange={isMobile ? 3 : 1} />
            </div>
          </>
        ) : (
          <NoTransactionsMessage iconLink={NoTransactionsImage} hideSubtext={cardType == 'physical'} />
        )}
      </div>
    </div>
  )
}

export default CardTransactions
