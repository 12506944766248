import { tr } from 'mmfintech-commons'
import WelcomeIcon2 from '@images/logo-full.svg?react'

import './welcomeContent2.scss'

type TWelcomeContent2Props = {}

export const WelcomeContent2 = ({}: TWelcomeContent2Props) => {
  return (
    <div className='welcome-step-2'>
      <div className='logo'>
        <WelcomeIcon2 />
      </div>
      <div className='title'>
        {tr('FRONTEND.WELCOME.MODAL.STEP_2.TITLE_2', 'We are happy that you registered with us!')}
      </div>
      <p className='description'>
        {tr(
          'FRONTEND.WELCOME.MODAL.STEP_2.DESCRIPTION_2',
          'Lets complete the final onboarding step so you can start depositing, sending and exchanging money!'
        )}
      </p>
    </div>
  )
}
