import settings from '@settings'

const IframeOtp = ({ cardId }) => {

    return (
        <iframe
            src={`${settings.cardsUrl}challenge?type=cardDetails&id=${cardId}`}
            allow="clipboard-read; clipboard-write"
            onLoad={async () => { }}
        />
    )
}

export default IframeOtp