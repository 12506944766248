import CoreButton from '@components/CoreButton'

import { GlobalContext, tr } from 'mmfintech-commons'
import { useContext } from 'react'

interface CardPinProps {
  cardPin?: string | number
}

export const CardPin = ({ cardPin }: CardPinProps) => {

  const { modalHide } = useContext(GlobalContext)


  return (
    <div className='card-pin-wrapper'>
      <div className='card-pin-title'>
        {tr('FRONTEND.CARDS.SETTINGS.CARD_PIN.MODAL.TEXT', 'Your card PIN')}
      </div>
      <div className='card-pin-content'>
        <span>
          {cardPin || tr('FRONTEND.CARDS.SETTINGS.CARD_PIN.MODAL.ERROR', 'PIN could not be shown')}
        </span>
      </div>
      <CoreButton
        fullWidth
        type='button'
        variation='primary'
        size='normal'
        title={tr('FRONTEND.BUTTONS.OK', 'OK')}
        onClick={modalHide}
      />
    </div>
  )
}
