import React from 'react'
import { NavLink } from 'react-router-dom'

import cn from 'classnames'

import { tr } from 'mmfintech-commons'
import { paths, useLogoutQry } from 'mmfintech-backend-api'

import MerchantLogo from '@components/MerchantLogo/MerchantLogo'

import ArrowDown from '@images/icons/chevron-down.svg?react'
import LogoutIcon from '@images/icons/logout.svg?react'
import SettingsIcon from '@images/icons/settings.svg?react'

import './profileDropdown.scss'

interface ProfileDropdownProps {
  onClick: () => void
  merchantName: string
  dropDownOpened?: boolean
}
const ProfileDropdown = ({ merchantName, onClick, dropDownOpened = false }: ProfileDropdownProps) => {
  const { logout } = useLogoutQry(true)

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    void logout()
    return false
  }

  return (
    <div className={cn('profile-dropdown-wrapper', { opened: dropDownOpened })} onClick={() => onClick && onClick()}>
      <MerchantLogo merchantName={merchantName} />

      {merchantName}

      <ArrowDown className={cn({ flipped: dropDownOpened })} />

      {dropDownOpened && (
        <div className='user-dropdown-menu-container'>
          <NavLink date-test='profile-dropdown-menu-settings' to={paths.settings()} className='menu-item'>
            <SettingsIcon />
            {tr('FRONTEND.HEADER.SETTINGS', 'Manage account')}
          </NavLink>
          <NavLink date-test='profile-dropdown-menu-logout' to='#' className='menu-item' onClick={handleLogoutClick}>
            <LogoutIcon />
            {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
          </NavLink>
        </div>
      )}
    </div>
  )
}

export default ProfileDropdown
