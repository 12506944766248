import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import CoreButton, { LoadingButton } from '@components/CoreButton'
import ArrowDown from '@images/icons/chevron-down.svg?react'
import ArrowUp from '@images/icons/chevron-up.svg?react'
import cn from 'classnames'

import { GlobalContext, isValidArray, isValidString, tr } from 'mmfintech-commons'

import CoreInput from '../CoreInput'
import SelectionElement from './SelectionElement'
import './coreSelect.scss'
import type { SelectionProps } from './selectionTypes'
import { SelectedIcon, filterOptions, selectedLabel } from './selectionUtils'

import ClearIcon from '@images/icons/close.svg?react'
import { AddAccountModal } from '@views/account/elements/AddAccountModal'

const CoreSelect = ({
  LeftIcon,
  label,
  name,
  type = 'default',
  options,
  onChange = () => { },
  required,
  error,
  hint,
  className,
  hideCaption,
  value,
  disabled,
  loading,
  allAccounts = false,
  clearable = false,
  enableAccountCreation = false
}: SelectionProps) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false)
  const [filterString, setFilterString] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<any[]>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [offsetErrorHeight, setOffsetErrorHeight] = useState<number>(0)
  const [offsetHintHeight, setOffsetHintHeight] = useState<number>(0)
  const [createdAccountId, setCreatedAccountId] = useState<any>(null)

  const [blockBlur, setBlockBlur] = useState<boolean>(false)

  const inputRef = useRef(null)

  const { modalShow } = useContext(GlobalContext)
  const handleAddAccountClick = () => {
    modalShow({
      content: <AddAccountModal setCreatedAccount={setCreatedAccountId} />,
      header: tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add New Account'),
      options: { closeOnClickOutside: true }
    })
  }

  useEffect(() => {
    setFilteredOptions(filterOptions(type, options, filterString))
  }, [filterString])

  useEffect(() => {
    setFilterString('')
    if (inputRef && inputRef.current) {
      const error = inputRef.current.querySelector('.core-input-error')
      const hint = inputRef.current.querySelector('.core-input-hint')
      setOffsetErrorHeight(error ? error.offsetHeight : 0)
      setOffsetHintHeight(hint ? hint.offsetHeight : 0)
    }
    // onBlur case
    if (!inputFocused && !selectedOption) {
      setFilterString('')
      if (!filteredOptions[0]) {
        setSelectedOption(null)
        return
      }
      if (filteredOptions[0]?.options) {
        const findOptions = filteredOptions.reduce((acc, options) => {
          if (options?.options?.length > 0) {
            acc.push(options)
          }
          return acc
        }, [])
        if (findOptions.length > 0) {
          onChange(name, findOptions[0]?.options?.[0].value)
        } else {
          setSelectedOption(null)
        }
      } else {
        if (filteredOptions.length === 1) {
          onChange(name, filteredOptions?.[0].value)
        }
      }
    }
  }, [inputFocused])

  useEffect(() => {
    if (isValidArray(options) && !isValidString(filterString)) {
      setFilteredOptions(options)
    }
  }, [options])

  useEffect(() => {
    if (type === 'account') {
      const foundAccount = filteredOptions?.find(account => account.id === createdAccountId)
      if (foundAccount) {
        setSelectedOption(foundAccount)
        onChange(name, foundAccount)
      }
    }
  }, [createdAccountId, filteredOptions])

  useMemo(() => {
    if (!value || value?.length === 0) {
      setSelectedOption(null)
      return
    }
    if (typeof value === 'string' || typeof value === 'number') {
      const foundOption = options.find(option => option.value === value)
      if (foundOption) {
        setSelectedOption(foundOption)
        return
      }
    }
    setSelectedOption(value)
  }, [value, options])

  return (
    <div ref={inputRef} className={cn(className, 'core-selection-wrapper', { disabled })}>
      {!inputFocused && selectedOption ? (
        <div
          className='selected-content'
          onClick={ev => {
            ev.preventDefault()
            ev.stopPropagation()
            setInputFocused(true)
          }}>
          <SelectionElement
            data-test='selected-content'
            data={selectedOption}
            type={type}
            setInputFocused={setInputFocused}
            isSelected
            hideCaption={hideCaption}
          />
        </div>
      ) : (
        <CoreInput
          LeftIcon={
            selectedOption && type !== 'default' ? (
              <SelectedIcon type={type} selectedOption={selectedOption} />
            ) : (
              LeftIcon
            )
          }
          label={selectedOption ? selectedLabel(type, selectedOption) : label}
          RightIcon={
            loading ? (
              <LoadingButton
                loadButtonClass='secondary-normal-loading-button'
                size={'normal'}
                variation='tertiary'
                style={{ backgroundColor: 'transparent' }}
              />
            ) : inputFocused ? (
              <ArrowUp onClick={() => setInputFocused(false)} />
            ) : (
              <ArrowDown onClick={() => setInputFocused(true)} />
            )
          }
          setFocus={setInputFocused}
          name={name}
          blockBlur={blockBlur}
          onChange={(_, value: string) => setFilterString(value)}
          focused={inputFocused}
          value={selectedOption?.value ? '' : filterString}
          required={required}
          error={error}
          hint={hint}
          onKeyDown={e => {
            if (e.key === 'Enter' && filteredOptions.length === 1) {
              if ((type === 'country' || type === 'default' || type === 'currency') && 'value' in filteredOptions[0]) {
                onChange(name, filteredOptions[0]?.value)
              } else {
                onChange(name, filteredOptions[0])
              }
              setSelectedOption(filteredOptions[0])
              setInputFocused(false)
            }
          }}
        />
      )}
      {inputFocused && (
        <div
          onMouseDown={() => {
            setBlockBlur(true)
          }}
          onMouseOut={() => setBlockBlur(false)}
          style={{ marginTop: -offsetErrorHeight + -offsetHintHeight - 4 }}
          className='core-selection-data-container'>
          {type === 'account' && allAccounts && (
            <SelectionElement
              data-test='selected-content'
              key={'all-accounts'}
              data={'all-accounts'}
              type='account'
              hideCaption={true}
              onSelect={() => {
                onChange(name, null)
                setSelectedOption(null)
                setInputFocused(false)
              }}
            />
          )}
          {isValidArray(options) ? (
            <>
              {filteredOptions.map((option, index) => {
                return (
                  <SelectionElement
                    data-test='selected-content'
                    key={index}
                    data={option}
                    type={type}
                    hideCaption={hideCaption}
                    onSelect={selectedData => {
                      if ((type === 'country' || type === 'default' || type === 'currency') && 'value' in selectedData) {
                        onChange(name, selectedData?.value)
                      } else {
                        onChange(name, selectedData)
                      }
                      setSelectedOption(selectedData)
                      setInputFocused(false)
                    }}
                  />
                )
              })}
              {enableAccountCreation && (
                <div className='add-account-button'>
                  <CoreButton
                    variation='primary'
                    fullWidth
                    size='small'
                    title={tr('FRONTEND.SELECT.ADD_ACCOUNT', 'Add new account')}
                    onClick={handleAddAccountClick}
                  />
                </div>
              )}
            </>
          ) : (
            <div className='core-selections-no-options'>
              {tr('FRONTEND.ACCOUNTS.NO_VALID_OPTIONS', 'No valid options')}
            </div>
          )}
        </div>
      )}
      {clearable && value && (
        <ClearIcon
          className='select-clear-button'
          onClick={() => {
            onChange(name, null)
            setSelectedOption(null)
            setInputFocused(false)
          }}
        />
      )}
    </div>
  )
}

export default CoreSelect
