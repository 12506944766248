// import AdvancedOptionsIcon from '@images/icons/advanced-card-options.svg?react'
import ChangePinIcon from '@images/icons/change-card-pin.svg?react'
import ShowPinIcon from '@images/icons/show-card-pin.svg?react'
// import ReplaceCardIcon from '@images/icons/replace-card.svg?react'
import StatementHistoryIcon from '@images/icons/statement-history.svg?react'
import TerminateCardIcon from '@images/icons/terminate-card.svg?react'
import UnfreezeCardIcon from '@images/icons/unfreeze-card.svg?react'
import SpendFromIcon from '@images/icons/spend-from.svg?react'


export const CardSettingsList = {
  spendFrom: [
    {
      icon: <SpendFromIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.SPEND_FROM.TITLE',
      titleAltKey: 'FRONTEND.CARDS.SETTINGS.SPEND_FROM.ALT_TITLE',
      titleAltValue: 'Selected accounts',
      titleValue: 'All accounts',
      key: 'spendFrom'
    }
  ],
  manage: [
    {
      icon: <StatementHistoryIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.STATEMENT_HISTORY.TITLE',
      titleValue: 'Statement history',
      key: 'statementHistory'
    },
    // {
    //   icon: <AdvancedOptionsIcon />,
    //   titleKey: 'FRONTEND.CARDS.SETTINGS.ADVANCED_OPTIONS.TITLE',
    //   titleValue: 'Advanced card options',
    //   key: 'advancedOptions'
    // }
  ],
  settings: [
    {
      icon: <ShowPinIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.SHOW_PIN.TITLE',
      titleValue: 'Show PIN',
      key: 'showPin'
    },
    {
      icon: <ChangePinIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.CHANGE_PIN.TITLE',
      titleValue: 'Change PIN',
      key: 'changePin'
    },
    {
      icon: <UnfreezeCardIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.UNFREEZE_CARD.TITLE',
      titleValue: 'Unfreeze card',
      key: 'unfreezeCard'
    }
    // {
    //   icon: <ReplaceCardIcon />,
    //   titleKey: 'FRONTEND.CARDS.SETTINGS.REPLACE_CARD.TITLE',
    //   titleValue: 'Replace card',
    //   key: 'replaceCard'
    // }
  ],
  rest: [
    {
      icon: <TerminateCardIcon />,
      titleKey: 'FRONTEND.CARDS.SETTINGS.TERMINATE_CARD.TITLE',
      titleValue: 'Terminate card',
      key: 'terminateCard'
    }
  ]
}
