import { useContext, useMemo, useState } from 'react'

import EditCardPin from '@components/CardSettings/modals/EditCardPin'
import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'

import { useActivatePhysicalCardMutation } from 'mmfintech-backend-api'
import { GlobalContext, tr, translateError } from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import './activateCard.scss'

const ActivateCard = ({ cardDetails }) => {
  const { id, isVirtual, cardBrand } = cardDetails || {}
  const [activateCard, { isLoading, error }] = useActivatePhysicalCardMutation()
  const [cvc, setCvc] = useState('')
  const { modalShow } = useContext(GlobalContext)

  const isPhysicalVisaCard = useMemo(() => isVirtual === false && cardBrand === 'VISA', [isVirtual, cardBrand])
  const cvcLength = useMemo(() => (isPhysicalVisaCard ? 6 : 3), [isPhysicalVisaCard])

  const handleActivate = async () => {
    const sendCode = isPhysicalVisaCard ? { activationCode: cvc } : { cvc }

    try {
      await activateCard({ prepaidCardId: id, ...sendCode }).unwrap()
      modalShow({
        options: { closeOnClickOutside: false },
        header: tr('FRONTEND.CARDS.SETTINGS.MODAL.SET_PIN.TITLE', 'Set card PIN'),
        content: <EditCardPin prepaidCardId={id} />
      })
    } catch (err) {}
  }

  return (
    <div className='activate-card-wrapper'>
      <p className='activate-card-text'>
        {isPhysicalVisaCard
          ? tr(
              'FRONTEND.CARDS.SETTINGS.MODAL.ACTIVATE_CARD.PHYSICAL_VISA_TEXT',
              'Enter the card activation code, provided in the envelope in which you received the card'
            )
          : tr(
              'FRONTEND.CARDS.SETTINGS.MODAL.ACTIVATE_CARD.TEXT',
              'Enter the CVC code located on the back of your card'
            )}
      </p>
      <CoreInput
        type='password'
        label={`${cvcLength} ${tr('FRONTEND.CARDS.SETTINGS.MODAL.ACTIVATE_CARD.INPUT_LABEL', `digits`)}`}
        value={cvc}
        autoComplete='off'
        name='cvc'
        maxLength={cvcLength}
        onChange={(_: string, value: string) => {
          setCvc(value)
        }}
      />
      <ErrorDisplay error={error && translateError(error)} />
      <CoreButton
        fullWidth
        size='normal'
        variation='primary'
        title={tr('FRONTEND.CARDS.ACTIVATE.BUTTON', 'Activate')}
        isLoading={isLoading}
        onClick={handleActivate}
        disabled={cvc?.length !== cvcLength}
      />
    </div>
  )
}

export default ActivateCard
