import React from 'react'
import { NavLink } from 'react-router-dom'

import cn from 'classnames'

import { tr } from 'mmfintech-commons'
import { coreMenuItems } from '@constants'
import { paths, useAuth, useLogoutQry } from 'mmfintech-backend-api'

import IconClose from '@images/icons/cross-button-icon.svg?react'
import LogoutIcon from '@images/icons/logout.svg?react'
import SettingsIcon from '@images/icons/settings.svg?react'

import { MenuItem } from '../CoreMenu'

import { LoginStatusEnum } from 'mmfintech-commons-types'

import './hamburger.scss'

interface HamburgerMenuProps {
  opened: boolean
  hideMenu: () => void
  Logo: React.FC
}

function HamburgerMenu({ Logo, opened, hideMenu }: HamburgerMenuProps) {
  const userStatus = useAuth()
  const isLoggedIn = () => userStatus === LoginStatusEnum.LOGGED_IN

  const { logout } = useLogoutQry(true)

  const handleLogoutClick = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    hideMenu()
    void logout()
    return false
  }

  return (
    <div className={cn('hamburger-menu-wrapper', { 'hamburger-opened': opened })}>
      <div data-test='hamburger-menu-logo' className='upper'>
        <Logo data-test='hamburger-menu-logo' />
        <div data-test='hamburger-menu-close-button' className='close-hamburger' onClick={hideMenu}>
          <IconClose />
        </div>
      </div>
      <div className='items-container'>
        {isLoggedIn() && (
          <>
            <div className='hamburger-core-navigation'>
              {coreMenuItems.map((item: MenuItem) => {
                return (
                  <NavLink
                    data-test={`hamburger-menu-element-${item.label}`}
                    key={item.label}
                    to={item.path}
                    onClick={hideMenu}
                    className='menu-item-wrapper'>
                    <img data-test={`hamburger-menu-element-icon-${item.label}`} src={item.imageSrc} alt='' />
                    <span data-test={`hamburger-menu-element--label${item.label}`}>
                      {tr(item.localization, item.label)}
                    </span>
                  </NavLink>
                )
              })}
            </div>
            <div className='hamburger-additional-navigation'>
              <NavLink
                data-test='hamburger-additional-navigation-settings-button'
                to={paths.settings()}
                onClick={hideMenu}
                className='menu-item-wrapper'>
                <SettingsIcon />
                {tr('FRONTEND.HEADER.SETTINGS', 'Manage account')}
              </NavLink>
              <NavLink
                data-test='hamburger-additional-navigation-sign-out-button'
                to={paths.login()}
                className='menu-item-wrapper logout-link'
                onClick={handleLogoutClick}>
                <LogoutIcon />
                {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
              </NavLink>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default HamburgerMenu
