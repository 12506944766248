import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react'
import toast from 'react-hot-toast'
import { useHistory } from 'react-router-dom'

import ActivateCard from '@components/ActivateCard/ActivateCard'
import CardOrderModal from '@components/CardOrderModal'
import { CardSettings } from '@components/CardSettings'
import ConfirmationModal from '@components/ConfirmationDeleteModal'
import CoreButton from '@components/CoreButton'
import SnowFallBackground from '@components/CoreSnowFallBackground'
import CoreTabs from '@components/CoreTabs'
import RotatingCard from '@components/RotatingCard'
import SidebarRight from '@components/SidebarRight'
import { useMatchMedia } from '@hooks'
import ArrowIcon from '@images/icons/chevron-up-black.svg?react'
import CreditCardIcon from '@images/icons/credit-card-icon.svg?react'
import ClosedEyeIcon from '@images/icons/eye-close.svg?react'
import OpenedEyeIcon from '@images/icons/eye.svg?react'
import FreezeIcon from '@images/icons/freeze.svg?react'
import PlusIcon from '@images/icons/red-plus.svg?react'
import SettingsIcon from '@images/icons/settings.svg?react'
import BlockedBalanceIcon from '@images/icons/show-card-block.svg?react'
import cn from 'classnames'

import {
  paths,
  responseDoesNotContainsChallenge,
  useChangeCardStatusMutation,
  useGetMerchantQuery,
  useLazyShowCardDetailsQuery,
  useMerchantAccounts
} from 'mmfintech-backend-api'
import { GlobalContext, OtpContext, formatMoney, isValidArray, tr, translateError } from 'mmfintech-commons'

import './cardPodium.scss'

import CardPodiumBackgroundImage from '@images/card-podium-background.png'
import InactiveCardBackgroundImage from '@images/inactive-card-background.png'

interface CardPodiumProps {
  prepaidCards?: any[]
  selectedTab?: 'virtual' | 'physical'
  setSelectedTab?: Dispatch<SetStateAction<'virtual' | 'physical'>>
  setSelectedCard?: Dispatch<any>
  setTransactionLoading?: Dispatch<any>
  transactionLoading?: boolean
}

const CardPodium = ({
  prepaidCards,
  selectedTab,
  setSelectedTab,
  setSelectedCard,
  setTransactionLoading,
  transactionLoading
}: CardPodiumProps) => {
  const [cardFlip, setCardFlip] = useState(null)
  const [cardFrozen, setCardFrozen] = useState<boolean>()
  const [visibleSide, setVisibleSide] = useState<'front' | 'back'>('front')
  const [cardDetails, setCardDetails] = useState(null)
  const [sidebarOpened, setSidebarOpened] = useState<boolean>(false)
  const [selectedCardIndex, setSelectedCardIndex] = useState<number>(0)
  const [iframeDetailsLoading, setIframeDetailsLoading] = useState<boolean>(false)
  const isMobile = useMatchMedia({ breakpoint: 690, prefix: 'max' })
  const history = useHistory()
  const { modalShow, modalHide, setIframeMessage, iframeMessage } = useContext(GlobalContext) as any
  const { setOtpOnSuccess } = useContext(OtpContext)

  const cardCarouselRef = useRef<HTMLDivElement>()

  const [showCardDetails, { isFetching: cardDetailsLoading }] = useLazyShowCardDetailsQuery()
  const [changeCardStatus, { isLoading: isFreezing }] = useChangeCardStatusMutation()
  const { activeAccounts } = useMerchantAccounts()

  const { data: merchant } = useGetMerchantQuery()
  const { capabilities } = merchant || {}
  const { enableCardIFrames, enableCardMultispending } = (capabilities as any) || {}

  const virtualCards = prepaidCards?.filter(card => card.isVirtual) || []
  const physicalCards = prepaidCards.filter(card => !card.isVirtual) || []
  const selectedCards = selectedTab === 'physical' ? physicalCards : virtualCards

  const cardAccount = () => {
    let cardAccountId = null
    if (selectedTab === 'virtual') {
      cardAccountId = virtualCardDetails()?.accountId
    } else {
      cardAccountId = physicalCardDetails()?.accountId
    }
    return activeAccounts.find(acc => acc.id == cardAccountId) || {}
  }

  const virtualCardDetails = () => {
    if (isValidArray(virtualCards)) {
      if (virtualCards[selectedCardIndex]) {
        return virtualCards[selectedCardIndex]
      }
    }
    return null
  }

  const physicalCardDetails = () => {
    if (isValidArray(physicalCards)) {
      // cardCarouselRef.current && cardCarouselRef.current.classList.remove('select-next')
      // cardCarouselRef.current && cardCarouselRef.current.classList.remove('select-previous')
      if (physicalCards[selectedCardIndex]) {
        return physicalCards[selectedCardIndex]
      }
      // if (physicalCards[0]) {
      //   setSelectedCardIndex(0)
      //   return physicalCards[0]
      // }
    }
    return null
  }

  const selectedCardDetails = () => {
    return selectedTab == 'physical' ? physicalCardDetails() : virtualCardDetails()
  }

  const cardExists = () => !!selectedCardDetails()

  const flipCard = async () => {
    if (cardDetails == '_REVEAL') return
    if (!cardDetails) {
      if (enableCardIFrames) {
        setIframeMessage(null)
        setIframeDetailsLoading(true)
        return setCardDetails('_REVEAL')
      }
      if (selectedCardDetails() && selectedCardDetails().id) {
        setOtpOnSuccess(() => result => {
          if (result) {
            setCardDetails(result)
            setCardFlip({
              detailsShown: true,
              actionCalled: Date.now()
            })
          }
        })
        const response = await showCardDetails({ prepaidCardId: selectedCardDetails().id }).unwrap()
        if (response && responseDoesNotContainsChallenge(response)) {
          setCardDetails(response)
          setCardFlip({
            detailsShown: true,
            actionCalled: Date.now()
          })
          setOtpOnSuccess(null)
        }
      }
    } else {
      if (visibleSide == 'back') {
        return setCardFlip({
          detailsShown: false,
          actionCalled: Date.now()
        })
      }
      setCardFlip({
        detailsShown: true,
        actionCalled: Date.now()
      })
    }
  }

  const cardFreezeSuccess = () => {
    setCardFrozen(cardFrozen => !cardFrozen)
    setVisibleSide('front')
    setCardFlip({
      detailsShown: false,
      actionCalled: Date.now()
    })
  }

  const handleCardFreeze = async () => {
    if (selectedCardDetails() && selectedCardDetails().id) {
      setOtpOnSuccess(() => cardFreezeSuccess)
      const newCardStatus = cardFrozen ? 'ACTIVE' : 'FROZEN'
      try {
        const response = await changeCardStatus({
          prepaidCardId: selectedCardDetails().id,
          prepaidCardStatus: newCardStatus
        }).unwrap()
        if (responseDoesNotContainsChallenge(response)) {
          cardFreezeSuccess()
        }
      } catch (error) {
        const message = translateError(error)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  const selectPreviousCard = () => {
    if (transactionLoading || selectedCardIndex === 0) return
    setCardFlip({
      detailsShown: false,
      actionCalled: Date.now()
    })
    setCardDetails(null)
    setTransactionLoading(true)
    cardCarouselRef.current && cardCarouselRef.current.classList.add('select-next')

    setTimeout(() => {
      setSelectedCardIndex(curr => curr - 1)
      cardCarouselRef.current && cardCarouselRef.current.classList.remove('select-next')
      setTransactionLoading(false)
    }, 1_000)
  }

  const selectNextCard = () => {
    if (transactionLoading || selectedCardIndex + 1 > selectedCards?.length) return
    setCardFlip({
      detailsShown: false,
      actionCalled: Date.now()
    })
    setCardDetails(null)
    setTransactionLoading(true)
    cardCarouselRef.current && cardCarouselRef.current.classList.add('select-previous')

    setTimeout(() => {
      setSelectedCardIndex(curr => curr + 1)
      cardCarouselRef.current && cardCarouselRef.current.classList.remove('select-previous')
      setTransactionLoading(false)
    }, 1_000)
  }

  const selectCardIndex = (index: number) => {
    if (selectedCardIndex == index) return
    setCardFlip({
      detailsShown: false,
      actionCalled: Date.now()
    })
    setCardDetails(null)
    setTransactionLoading(true)
    const animationClass = index < selectedCardIndex ? 'select-next' : 'select-previous'
    cardCarouselRef.current && cardCarouselRef.current.classList.add(animationClass)
    setTimeout(() => {
      setSelectedCardIndex(index)
      cardCarouselRef.current && cardCarouselRef.current.classList.remove(animationClass)
      setTransactionLoading(false)
    }, 1_000)
  }

  const tabOptions = [
    { label: tr('FRONTEND.CARDS.CARD_TYPE.PHYSICAL', 'Physical'), value: 'physical' },
    { label: tr('FRONTEND.CARDS.CARD_TYPE.VIRTUAL', 'Virtual'), value: 'virtual' }
  ]

  useEffect(() => {
    if (selectedCardDetails()?.status === 'FROZEN') {
      setCardFrozen(true)
    } else {
      setCardFrozen(false)
    }
  }, [prepaidCards, selectedTab, selectedCardIndex])

  useEffect(() => {
    if (selectedTab == 'physical') {
      setSelectedCard(physicalCardDetails())
    } else {
      setSelectedCard(virtualCardDetails())
    }
  }, [selectedCardIndex, selectedTab])

  useEffect(() => {
    setSelectedCardIndex(0)
  }, [selectedTab])

  useEffect(() => {
    if (iframeMessage == 'DETAILS_LOADED') {
      setIframeDetailsLoading(false)
      setCardFlip({
        detailsShown: true,
        actionCalled: Date.now()
      })
      setCardDetails(true)
      setIframeMessage(null)
    }
    if (iframeMessage == 'CARD_DETAILS_CANCEL') {
      setIframeDetailsLoading(false)
    }
    if (iframeMessage == 'CARD_DETAILS_ERROR') {
      setIframeDetailsLoading(false)
      setCardDetails(null)
    }
    if (iframeMessage === 'REVEAL_DETAILS') {
      setIframeDetailsLoading(true)
    }
    if (iframeMessage === 'OPEN_IFRAME_MODAL') {
      setIframeDetailsLoading(false)
      setCardDetails(null)
    }
  }, [iframeMessage])

  const handleOrderClick = () => {
    modalShow({
      header: tr('FRONTEND.DASHBOARD.ORDER_CARDS.TITLE', 'Order a card'),
      content: <CardOrderModal onClose={modalHide} />,
      options: {
        closeOnClickOutside: false
      }
    })
  }

  return (
    <>
      <SidebarRight
        content={
          <CardSettings
            onClose={() => setSidebarOpened(false)}
            cardType={selectedTab}
            setCardType={tab => {
              setCardFlip({
                detailsShown: false,
                actionCalled: Date.now()
              })
              setCardDetails(null)
              setSelectedTab(tab)
            }}
            tabOptions={tabOptions}
            cardNumber={cardDetails ? cardDetails.pan : cardExists() ? selectedCardDetails().last4Digits : ''}
            disableTabSelect={isValidArray(prepaidCards) && prepaidCards.length < 2}
            cardDetails={selectedCardDetails()}
            accountDetails={cardAccount()}
          />
        }
        options={{ size: 'extra-large', closeOnClickOutside: false }}
        onClose={() => setSidebarOpened(false)}
        visible={sidebarOpened}
      />
      <SnowFallBackground backgroundColor='transparent' snowFalling={cardFrozen}>
        <div className={cn('card-podium-wrapper', { frozen: cardFrozen, unfrozen: cardFrozen === false })}>
          <div className='card-podium-frozen-cover-wrapper'></div>
          <img
            className={cn('card-podium-background-image', { 'centered-background': !cardExists() })}
            src={cardExists() ? CardPodiumBackgroundImage : InactiveCardBackgroundImage}
            alt='card-background-image'
          />
          <div className='card-podium-container'>
            <div className='card-podium-top-section'>
              <div className='card-podium-top-section-left-side'>
                <div className='card-podium-title'>{tr('FRONTEND.CARDS.TITLE', 'Cards')}</div>
                {/* {!enableCardMultispending && ( */}
                <CoreButton
                  title={tr('FRONTEND.CARDS.ADD_BUTTON_LABEL', 'Add new')}
                  className='card-podium-add-card-button'
                  size='normal'
                  LeftIcon={<CreditCardIcon />}
                  onClick={handleOrderClick}
                />
                {/* )} */}
              </div>
              <CoreTabs
                tabs={tabOptions}
                preselected={selectedTab}
                onChangeTab={tab => {
                  setCardFlip({
                    detailsShown: false,
                    actionCalled: Date.now()
                  })
                  setCardDetails(null)
                  setSelectedTab(tab)
                }}
              />
            </div>
            <div className='card-podium-main-section' ref={cardCarouselRef}>
              {selectedCards?.length > 1 && (
                <>
                  <button
                    className={cn('prev-card-button', { disabled: selectedCardIndex === 0 })}
                    onClick={selectPreviousCard}>
                    <ArrowIcon />
                  </button>
                  <div className='leftmost-card'>
                    <RotatingCard
                      cardNumber={cardDetails && cardDetails.pan}
                      lastFourDigits={
                        selectedCards[selectedCardIndex - 1] && selectedCards[selectedCardIndex - 1].last4Digits
                      }
                      cvv={cardDetails && cardDetails.cvv}
                      validity={
                        cardDetails &&
                        `${cardDetails.expiryMonth?.padStart(2, '0')}/${cardDetails.expiryYear?.slice(-2)}`
                      }
                      cardType={selectedTab}
                      isFrozen={cardFrozen}
                      setVisibleSide={() => { }}
                      cardBrand={selectedCards[selectedCardIndex - 1] && selectedCards[selectedCardIndex - 1]?.cardBrand}
                      cardHolderName={
                        selectedCards[selectedCardIndex - 1] && selectedCards[selectedCardIndex - 1]?.cardHolderName
                      }
                    />
                  </div>
                </>
              )}

              <RotatingCard
                cardNumber={cardDetails && cardDetails?.pan}
                lastFourDigits={cardExists() && selectedCardDetails().last4Digits}
                cvv={cardDetails && cardDetails?.cvv}
                validity={
                  cardDetails && `${cardDetails?.expiryMonth?.padStart(2, '0')}/${cardDetails?.expiryYear?.slice(-2)}`
                }
                cardId={selectedCardDetails()?.id}
                flip={cardFlip}
                setCardFlip={setCardFlip}
                setVisibleSide={setVisibleSide}
                enableRotation={!!cardDetails && cardDetails != '_REVEAL' && !cardFrozen}
                visibleSide={visibleSide}
                cardType={selectedTab}
                isFrozen={cardFrozen}
                cardDetails={cardDetails}
                cardBrand={selectedCardDetails()?.cardBrand}
                hasShadow={!!cardExists() && selectedCards?.length == 1}
                cardHolderName={selectedCardDetails()?.cardHolderName}
              />
              {selectedCards?.length > 1 && (
                <>
                  <button
                    className={cn('next-card-button', {
                      disabled: !selectedCards?.length || selectedCards?.length <= selectedCardIndex + 1
                    })}
                    onClick={selectNextCard}>
                    <ArrowIcon />
                  </button>
                  <div className='rightmost-card'>
                    <RotatingCard
                      cardNumber={cardDetails && cardDetails.pan}
                      lastFourDigits={
                        selectedCards[selectedCardIndex + 1] && selectedCards[selectedCardIndex + 1].last4Digits
                      }
                      cvv={cardDetails && cardDetails.cvv}
                      validity={
                        cardDetails &&
                        `${cardDetails.expiryMonth?.padStart(2, '0')}/${cardDetails.expiryYear?.slice(-2)}`
                      }
                      cardType={selectedTab}
                      setVisibleSide={() => { }}
                      isFrozen={cardFrozen}
                      cardBrand={selectedCards[selectedCardIndex + 1] && selectedCards[selectedCardIndex + 1]?.cardBrand}
                      cardHolderName={
                        selectedCards[selectedCardIndex + 1] && selectedCards[selectedCardIndex + 1]?.cardHolderName
                      }
                    />
                  </div>
                </>
              )}
            </div>

            {isValidArray(selectedCards) && selectedCards?.length > 1 && (
              <div className='select-card-dots'>
                {selectedCards.map((card, index) => {
                  return (
                    <div className='dot-element' key={card?.id} onClick={() => selectCardIndex(index)}>
                      <div className={cn('card-dot', { selected: index === selectedCardIndex })} />
                    </div>
                  )
                })}
              </div>
            )}

            {cardExists() && (
              <>
                {selectedCardDetails().status === 'NOT_ACTIVATED' && (
                  <div className='activate-physical-card-container'>
                    <CoreButton
                      className='activate-button'
                      size='normal'
                      title={tr('FRONTEND.CARDS.CARD_BALANCE.ACTIVATE', 'Activate')}
                      variation='tertiary'
                      onClick={() =>
                        modalShow({
                          header: tr('FRONTEND.SECURITY.ACTIVATE_CARD.TITLE', 'Activate card'),
                          content: <ActivateCard cardDetails={selectedCardDetails()} />
                        })
                      }
                    />
                    <div className='activate-card-instructions'>
                      {selectedCardDetails().cardBrand === 'VISA' ? tr(
                        'FRONTEND.SECURITY.ACTIVATE_CARD.INSTRUCTIONS_VISA',
                        'You can activate your card, after it gets delivered to you, by entering the card activation code, provided in the envelope in which you received the card.'
                      ) : tr(
                        'FRONTEND.SECURITY.ACTIVATE_CARD.INSTRUCTIONS',
                        'You can activate your card, after it gets delivered to you, by entering the 3-digit CVC code, which you can see on the back of your card.'
                      )}
                    </div>
                  </div>
                )}
                {(selectedCardDetails().status === 'ACTIVE' || selectedCardDetails().status === 'FROZEN') && (
                  <div
                    className={cn('card-podium-bottom-section', {
                      frozen: cardFrozen,
                      unfrozen: cardFrozen === false
                    })}>
                    <div className='card-podium-bottom-section-content' >
                      {enableCardMultispending ? (
                        <div className='button-tool-tip-container'>
                          <CoreButton
                            LeftIcon={<PlusIcon />}
                            CollapsedIcon={<PlusIcon />}
                            className='add-funds-button'
                            size='normal'
                            title={tr('FRONTEND.CARDS.CARD_BALANCE.ADD_FUNDS', 'Add funds')}
                            variation='tertiary'
                            collapsed={isMobile}
                            onClick={() =>
                              history.push({ pathname: paths.banking.deposit() })
                            }
                          />
                          <div className='button-tool-tip-component'>{tr(
                            'FRONTEND.CARDS.CARD_BALANCE.ADD_TOOLTIP',
                            'Top up any account and start spending with your card.'
                          )}</div>
                        </div>
                      ) : (
                        <div className={cn('card-podium-balance-values', { 'loading-card': transactionLoading })}>
                          <div className='card-podium-balance'>
                            <div className='card-balance-title'>
                              {tr('FRONTEND.CARDS.CARD_BALANCE.TITLE', 'Available balance')}
                            </div>
                            <div className='card-balance-value' translate='no'>
                              {formatMoney(cardAccount()?.availableBalance, selectedCardDetails()?.currency)}
                            </div>
                          </div>
                          {cardAccount()?.blockedBalance > 0 && (
                            <div className='card-podium-balance-blocked-wrapper'>
                              <CoreButton
                                className={cn('opaque-button')}
                                LeftIcon={<BlockedBalanceIcon />}
                                CollapsedIcon={<BlockedBalanceIcon />}
                                size='normal'
                                variation='primary'
                                collapsed
                                onClick={() => setSidebarOpened(true)}
                              />
                              <div className='card-podium-balance-blocked-value'>
                                <div className='card-balance-title'>
                                  {tr('FRONTEND.CARDS.CARD_BALANCE.BLOCKED_TITLE', 'Blocked balance')}
                                </div>
                                <div className='card-balance-blocked-value'>
                                  {formatMoney(cardAccount()?.blockedBalance, selectedCardDetails()?.currency)}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      <div className='card-podium-actions'>
                        {!cardFrozen && (
                          <>
                            {!enableCardMultispending && (
                              <CoreButton
                                LeftIcon={<PlusIcon />}
                                CollapsedIcon={<PlusIcon />}
                                className='add-funds-button'
                                size='normal'
                                title={tr('FRONTEND.CARDS.CARD_BALANCE.ADD_FUNDS', 'Add funds')}
                                variation='tertiary'
                                collapsed={isMobile}
                                onClick={() =>
                                  history.push({
                                    pathname: paths.banking.deposit(),
                                    state: { preselectedAccount: selectedCardDetails()?.accountId }
                                  })
                                }
                              />
                            )}
                            <CoreButton
                              className={cn('opaque-button', { frozen: cardFrozen, unfrozen: cardFrozen === false })}
                              LeftIcon={visibleSide == 'back' ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                              CollapsedIcon={visibleSide == 'back' ? <OpenedEyeIcon /> : <ClosedEyeIcon />}
                              size='normal'
                              title={
                                visibleSide == 'back'
                                  ? tr('FRONTEND.CARDS.CARD_BALANCE.HIDE_DETAILS', 'Hide details')
                                  : tr('FRONTEND.CARDS.CARD_BALANCE.SHOW_DETAILS', 'Show details')
                              }
                              variation='primary'
                              collapsed
                              onClick={flipCard}
                              isLoading={cardDetailsLoading || iframeDetailsLoading}
                            />
                          </>
                        )}

                        <CoreButton
                          className={cn('freeze-button', { frozen: cardFrozen, unfrozen: cardFrozen === false })}
                          LeftIcon={<FreezeIcon />}
                          CollapsedIcon={<FreezeIcon />}
                          size='normal'
                          title={
                            cardFrozen
                              ? tr('FRONTEND.CARDS.CARD_BALANCE.UNFREEZE', 'Unfreeze')
                              : tr('FRONTEND.CARDS.CARD_BALANCE.FREEZE', 'Freeze')
                          }
                          variation={cardFrozen ? 'tertiary' : 'primary'}
                          collapsed
                          isLoading={isFreezing}
                          onClick={() => {
                            cardFrozen
                              ? handleCardFreeze()
                              : modalShow({
                                header: tr('FRONTEND.CARDS.CARDS_MODAL.TITLE', 'Freezing card'),
                                content: (
                                  <ConfirmationModal
                                    question={
                                      <>
                                        {tr(
                                          'FRONTEND.CARDS.CARDS_MODAL.CONFIRM1',
                                          'You are about to freeze your card which will prevent its usage.'
                                        )}
                                        <br />
                                        {tr(
                                          'FRONTEND.CARDS.CARDS_MODAL.CONFIRM2',
                                          'Unfreezing the card will require OTP confirmation. Do you wish to proceed?'
                                        )}
                                      </>
                                    }
                                    confirmCaption={tr('FRONTEND.CARDS.CARDS_MODAL.BUTTON_YES', 'Yes')}
                                    onConfirm={handleCardFreeze}
                                  />
                                )
                              })
                          }}
                        />

                        <CoreButton
                          className={cn('opaque-button', { frozen: cardFrozen, unfrozen: cardFrozen === false })}
                          LeftIcon={<SettingsIcon />}
                          CollapsedIcon={<SettingsIcon />}
                          size='normal'
                          title={tr('FRONTEND.CARDS.CARD_BALANCE.SETTINGS', 'Settings')}
                          variation='primary'
                          collapsed
                          onClick={() => setSidebarOpened(true)}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {(selectedCardDetails().status === 'INITIAL' || selectedCardDetails().status === 'PENDING_ISSUING') && (
                  <div className='issuing-physical-card-container'>
                    <div className='activate-card-instructions'>
                      {tr(
                        'FRONTEND.SECURITY.ISSUING_CARD.INSTRUCTIONS',
                        'Your card is currently being issued. Once it is issued you will be notified and the card will be sent to the provided address.'
                      )}
                    </div>
                  </div>
                )}

                {selectedCardDetails().status === 'LOCKED_ACTIVATION' && (
                  <div className='issuing-physical-card-container'>
                    <div className='activate-card-instructions'>
                      {tr(
                        'FRONTEND.SECURITY.ISSUING_CARD.LOCKED',
                        'Card activation is suspended due to too failed attempts. Please contact customer support'
                      )}
                    </div>
                  </div>
                )}
                {selectedCardDetails().status === 'FAILED_ISSUING' && (
                  <div className='issuing-physical-card-container'>
                    <div className='activate-card-instructions'>
                      {tr(
                        'FRONTEND.SECURITY.ISSUING_CARD.FAILED',
                        'Card issuing failed. Please contact customer support'
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </SnowFallBackground>
    </>
  )
}

export default CardPodium
