import { useEffect, useMemo } from 'react'
import toast from 'react-hot-toast'

import { useAccountEditQry } from 'mmfintech-backend-api'
import { currenciesAlpha3, tr } from 'mmfintech-commons'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

import OverwriteIcon from '@images/icons/profile-account-details-overwrite.svg?react'

import './styled/accountDetails.scss'

const AccountDetails = ({ selectedAccount }: { selectedAccount: AccountBalanceResponse }) => {
  const { currencyCode, id, name, dedicatedMerchantBankAccounts } = selectedAccount || {}
  const { iban, holderName, accountNumber, bankCode, bankName } = dedicatedMerchantBankAccounts?.[0] || {}

  const handleSuccessfulChange = () => {
    toast.remove()
    toast.success(tr('FRONTEND.ACCOUNTS.TOAST.CHANGE_SUCCESS', 'Account successfully changed'))
  }

  const { formValues, editAccountError, editAccountFetching, handleSubmit } = useAccountEditQry({
    accountId: id,
    accountName: name,
    onSuccess: handleSuccessfulChange
  })

  const isUKAccount = useMemo(() => currencyCode == 'GBP', [currencyCode])

  useEffect(() => {
    if (selectedAccount) {
      formValues.setValue('name', name)
    }
  }, [selectedAccount])

  return (
    <div data-test='account-details-container-test' className='account-details-container'>
      <div className='account-details-form'>
        <CoreInput
          className='account-details-input'
          type='text'
          data-test='account-details-name'
          label={tr('FRONTEND.ACCOUNTS.DETAILS.NAME_LABEL', 'Account Name')}
          {...formValues.registerInput('name')}
          value={name}
          RightIcon={<OverwriteIcon />}
        />
        <ErrorDisplay error={editAccountError} />
        <div data-test='account-details-information-wrapper-test' className='account-details-information-wrapper'>
          <div data-test='account-details-information-currency-test' className='account-details-information'>
            <span
              data-test='account-details-information-currency-label-test'
              className='account-details-information-label'>
              {tr('FRONTEND.ACCOUNTS.DETAILS.LABEL_CURRENCY', 'Currency')}
            </span>
            <span
              data-test='account-details-information-currency-value-test'
              className='account-details-information-value'
              translate='no'>
              {currencyCode}
              {currenciesAlpha3?.[currencyCode]?.name ? `(${currenciesAlpha3?.[currencyCode]?.name})` : ''}
            </span>
          </div>

          {holderName && (
            <div data-test='account-details-information-holder-name-test' className='account-details-information'>
              <span
                data-test='account-details-information-holder-name-label-test'
                className='account-details-information-label'>
                {tr('FRONTEND.ACCOUNTS.DETAILS.HOLDER_NAME', 'Holder name')}
              </span>
              <span
                data-test='account-details-information-holder-name-value-test'
                className='account-details-information-value'>
                {holderName}
              </span>
            </div>
          )}
          {iban && (
            <div data-test='account-details-information-IBAN-test' className='account-details-information'>
              <span
                data-test='account-details-information-IBAN-label-test'
                className='account-details-information-label'>
                {tr('FRONTEND.ACCOUNTS.DETAILS.IBAN', 'IBAN')}
              </span>
              <span
                data-test='account-details-information-IBAN-value-test'
                className='account-details-information-value'>
                {iban}
              </span>
            </div>
          )}
          {accountNumber && (
            <div data-test='account-details-information-account-number-test' className='account-details-information'>
              <span
                data-test='account-details-information-account-number-label-test'
                className='account-details-information-label'>
                {tr('FRONTEND.ACCOUNTS.DETAILS.ACCOUNT_NUMBER', 'Account number')}
              </span>
              <span
                data-test='account-details-information-account-number-value-test'
                className='account-details-information-value'>
                {accountNumber}
              </span>
            </div>
          )}
          {bankCode && (
            <div data-test='account-details-information-bank-code-test' className='account-details-information'>
              <span
                data-test='account-details-information-bank-code-label-test'
                className='account-details-information-label'>
                {isUKAccount
                  ? tr('FRONTEND.ACCOUNTS.DETAILS.SORT_CODE', 'Sort code')
                  : tr('FRONTEND.ACCOUNTS.DETAILS.BANK_CODE', 'Bank code')}
              </span>
              <span
                data-test='account-details-information-bank-code-value-test'
                className='account-details-information-value'>
                {bankCode}
              </span>
            </div>
          )}
          {bankName && (
            <div data-test='account-details-information-bank-name-test' className='account-details-information'>
              <span
                data-test='account-details-information-bank-name-label-test'
                className='account-details-information-label'>
                {tr('FRONTEND.ACCOUNTS.DETAILS.BANK_NAME', 'Bank name')}
              </span>
              <span
                data-test='account-details-information-bank-name-value-test'
                className='account-details-information-value'>
                {bankName}
              </span>
            </div>
          )}
          <div data-test='account-details-information-ID-test' className='account-details-information'>
            <span data-test='account-details-information-ID-label-test' className='account-details-information-label'>
              {tr('FRONTEND.ACCOUNTS.DETAILS.LABEL_ID', 'ID')}
            </span>
            <span data-test='account-details-information-ID-value-test' className='account-details-information-value'>
              {id}
            </span>
          </div>
        </div>
        <CoreButton
          fullWidth
          disabled={formValues.getValue('name') === name}
          title={tr('FRONTEND.ACCOUNTS.DETAILS.BUTTON.SAVE', 'Save')}
          size='normal'
          data-test='submit-button'
          isLoading={editAccountFetching}
          onClick={handleSubmit}
        />
      </div>
    </div>
  )
}

export default AccountDetails
