import { FC } from 'react'

import { tr } from 'mmfintech-commons'
import { useGetMerchantQuery } from 'mmfintech-backend-api'

import RedOnboardingIcon from '@images/icons/onboarding-icon-red.svg?react'
import YellowOnboardingIcon from '@images/icons/onboarding-icon-yellow.svg?react'

import { MerchantAccountTypeEnum, OnboardingProviderEnum, OnboardingStatusEnum } from 'mmfintech-commons-types'

import './styled/onboardingBanner.scss'
import useOnboarding from './useOnboarding'

export const OnboardingBanner: FC = () => {
  const { data: merchant, isFetching } = useGetMerchantQuery()
  const { accountType, onboardingProvider, onboardingStatus } = merchant || {}

  const formatStatus = (onboardingStatus: string): string => {
    if (!onboardingStatus) return ''
    return onboardingStatus.charAt(0).toUpperCase() + onboardingStatus.replaceAll('_', ' ').toLocaleLowerCase().slice(1)
  }

  const { startOrContinueOnboarding } = useOnboarding()

  if (!isFetching && onboardingProvider === OnboardingProviderEnum.SUMSUB) {
    if (onboardingStatus === OnboardingStatusEnum.APPROVED_EXPIRING_DOCUMENT) {
      return (
        <div className='onboarding-banner-wrapper' onClick={() => startOrContinueOnboarding()}>
          <div className='banner-content-left'>
            <div className='icon'>
              <YellowOnboardingIcon />
            </div>
            <div className='banner-text'>
              {tr(
                'FRONTEND.ONBOARDING.EXPIRING_DOCUMENT.MESSAGE',
                'Your document is expiring, upload newer one to avoid limitation of services'
              )}
            </div>
          </div>
          <div className='red-dot' />
        </div>
      )
    }

    if (accountType === MerchantAccountTypeEnum.PROSPECT) {
      if (
        onboardingStatus === OnboardingStatusEnum.NOT_STARTED ||
        onboardingStatus === OnboardingStatusEnum.IN_PROGRESS ||
        onboardingStatus === OnboardingStatusEnum.REJECTED_RETRY
      ) {
        return (
          <div className='onboarding-banner-wrapper' onClick={() => startOrContinueOnboarding()}>
            <div className='banner-content-left'>
              <div className='icon'>
                <RedOnboardingIcon />
              </div>
              <div className='banner-text'>
                {tr('FRONTEND.ONBOARDING.RESUME_ALERT_TEXT', 'Verify your account')}
                <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
              </div>
            </div>
            <div className='red-dot' />
          </div>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.WAITING_ADDITIONAL_DOCUMENTS) {
        return (
          <div className='onboarding-banner-wrapper waiting-state' onClick={() => startOrContinueOnboarding()}>
            <div className='banner-content-left'>
              <div className='icon'>
                <YellowOnboardingIcon />
              </div>
              <div className='banner-text'>
                {tr(
                  'FRONTEND.ONBOARDING.ADDITIONAL_DOCUMENTS.MESSAGE',
                  'Complete the final step of your account activation.'
                )}
                <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
              </div>
            </div>
            <div className='red-dot' />
          </div>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.WAITING_QUESTIONNAIRE) {
        return (
          <div className='onboarding-banner-wrapper waiting-state' onClick={() => startOrContinueOnboarding()}>
            <div className='banner-content-left'>
              <div className='icon'>
                <YellowOnboardingIcon />
              </div>
              <div className='banner-text'>
                {tr(
                  'FRONTEND.ONBOARDING.QUESTIONNAIRE.MESSAGE',
                  'Complete the questionnaire to proceed with the account onboarding'
                )}
                <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
              </div>
            </div>
            <div className='red-dot' />
          </div>
        )
      }

      if (
        onboardingStatus === OnboardingStatusEnum.UNDER_REVIEW ||
        onboardingStatus === OnboardingStatusEnum.WAITING_COMPLIANCE_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_BUSINESS_AGREEMENT_APPROVAL ||
        onboardingStatus === OnboardingStatusEnum.WAITING_OPS_APPROVAL
      ) {
        return (
          <div className='onboarding-banner-wrapper waiting-state' onClick={() => startOrContinueOnboarding()}>
            <div className='banner-content-left'>
              <div className='icon'>
                <YellowOnboardingIcon />
              </div>
              <div className='banner-text'>
                {tr('FRONTEND.DASHBOARD.SUMSUB.UNDER_REVIEW_MESSAGE', 'Your account verification is under review.')}
                <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
              </div>
            </div>
          </div>
        )
      }

      if (onboardingStatus === OnboardingStatusEnum.REJECTED_FINAL) {
        return (
          <div className='onboarding-banner-wrapper' onClick={() => startOrContinueOnboarding()}>
            <div className='banner-content-left'>
              <div className='icon'>
                <RedOnboardingIcon />
              </div>
              <div className='banner-text'>
                <span>{tr('FRONTEND.DASHBOARD.SUMSUB.REJECTED_MESSAGE', 'Please contact Customer Service.')}</span>
                <div className='banner-subtext'>{formatStatus(onboardingStatus)}</div>
              </div>
            </div>
            <div className='red-dot' />
          </div>
        )
      }
    }
  }

  return null
}
