import React from 'react'
import 'react-circular-progressbar/dist/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import * as ReactDOM from 'react-dom'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-phone-input-2/lib/style.css'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { apiConfig, configuration } from 'mmfintech-backend-api'
import { Analytics } from 'mmfintech-commons'

import 'mmfintech-portal-commons/dist/main.css'

import App from './App'
import './i18n'
import './index.css'
import { store } from './store'

if (configuration.isProduction()) {
  const s = document.createElement('script')
  s.type = 'text/javascript'
  s.id = 'ze-snippet'
  s.src = 'https://static.zdassets.com/ekr/snippet.js?key=11194e11-2c80-4148-adb2-749829f57a3b'
  document.getElementsByTagName('body')[0].appendChild(s)
}

ReactDOM.render(
  <React.StrictMode>
    <Analytics
      enabled={configuration.isProduction()}
      codes={[apiConfig.VITE_GTM_CODE as string, apiConfig.VITE_GTM_CODE_TWO as string]}
    />
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('jeton-app')
)
