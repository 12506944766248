import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'

import cn from 'classnames'

import { useMatchMedia } from '@hooks'
import {
  GlobalContext,
  currenciesAlpha3,
  cutString,
  getCurrencySymbol,
  isValidArray,
  isValidString,
  tr
} from 'mmfintech-commons'

import CoreInput from '@components/CoreInput'
import CoreButton from '@components/CoreButton'
import { CurrencyIcon } from '@components/CurrencyIcon'
import { AddAccountModal } from './AddAccountModal'

import { AccountBalanceResponse } from 'mmfintech-commons-types'

import IBANIcon from '@images/icons/iban-icon.svg?react'
import LeftArrow from '@images/icons/left-arrow-button.svg?react'
import PlusIcon from '@images/icons/red-plus.svg?react'
import RightArrow from '@images/icons/right-arrow-button.svg?react'

import './styled/CarouselCards.scss'

const CarouselCards = ({
  accounts,
  selectedAccount,
  setSelectedAccount
}: {
  accounts: AccountBalanceResponse[]
  selectedAccount: AccountBalanceResponse
  setSelectedAccount: Dispatch<SetStateAction<AccountBalanceResponse>>
}) => {
  const carouselRef = useRef(null)
  const [selectable, setSelectable] = useState<boolean>(true)
  const prev = () => selectAccountElement(-1)
  const next = () => selectAccountElement(1)
  const isMobile = useMatchMedia({ breakpoint: 450, prefix: 'max' })
  const { modalShow } = useContext(GlobalContext)
  const [searchString, setSearchString] = useState<string>('')
  const [filteredAccounts, setFilteredAccounts] = useState<AccountBalanceResponse[]>(accounts)

  const selectAccountElement = (indexOffset: number, atIndex?: number): void => {
    if (carouselRef && carouselRef.current) {
      const selectedIndex = atIndex != undefined ? atIndex : filteredAccounts.indexOf(selectedAccount)
      const cardsNodeList = carouselRef.current.querySelectorAll('.carousel-cards-wrapper')
      if (selectedIndex >= 0 && filteredAccounts[selectedIndex + indexOffset]) {
        setSelectedAccount(filteredAccounts[selectedIndex + indexOffset])
        if (cardsNodeList && cardsNodeList[selectedIndex + indexOffset]) {
          cardsNodeList[selectedIndex + indexOffset].scrollIntoView({
            behavior: 'smooth',
            inline: 'center'
          })
        }
      }
    }
  }

  const onMouseDown = useCallback((e: React.BaseSyntheticEvent) => {
    e.preventDefault()

    const body = document.body

    const drag = mouseOverEvent => {
      body.style.cursor = 'pointer'
      setSelectable(false)
      carouselRef.current.scroll({
        left: (carouselRef.current.scrollLeft -= mouseOverEvent.movementX)
      })
    }
    carouselRef.current.addEventListener('mousemove', drag)
    const cancelDrag = () => {
      carouselRef.current.removeEventListener('mousemove', drag)
      body.style.cursor = 'default'
      setTimeout(() => {
        setSelectable(true)
      }, 200)
    }
    carouselRef.current.addEventListener('mouseup', cancelDrag, { once: true })
    carouselRef.current.addEventListener('mouseleave', cancelDrag, { once: true })
  }, [])

  const handleAddAccountClick = () => {
    modalShow({
      content: <AddAccountModal />,
      header: tr('FRONTEND.ACCOUNTS.CREATE.TITLE', 'Add New Account'),
      options: { closeOnClickOutside: true }
    })
  }

  useEffect(() => {
    if (isValidArray(accounts)) {
      setFilteredAccounts(accounts)
    }
  }, [accounts])

  useEffect(() => {
    if (searchString === '') {
      setFilteredAccounts(accounts)
    }
    if (isValidString(searchString)) {
      setFilteredAccounts(
        accounts.filter(acc => {
          return (
            acc?.name?.toLowerCase().includes(searchString.toLowerCase().trim().replace(/\s+/g, ' ')) ||
            acc.currencyCode.toLowerCase().includes(searchString.toLowerCase().trim())
          )
        })
      )
    }
  }, [searchString])

  useEffect(() => {
    carouselRef.current.addEventListener('mousedown', onMouseDown)
    return () => {
      carouselRef.current.removeEventListener('mousedown', onMouseDown)
    }
  }, [])

  return (
    <div data-test='carousel-cards-main-test' className='carousel-cards-main'>
      <div className='carousel-cards-arrows-wrapper'>
        <div className='carousel-cards-header-actions'>
          <span data-test='account-header-title-test' className='account-header-title'>
            {tr('FRONTEND.ACCOUNTS.MY_ACCOUNTS', 'My accounts')}
          </span>
          <CoreInput
            type='text'
            label={tr('FRONTEND.ACCOUNTS.SEARCH', 'Search')}
            onChange={(_, val) => setSearchString(val)}
          />
        </div>
        <CoreButton
          title={tr('FRONTEND.ACCOUNTS.ADD_NEW', 'Add new')}
          className='carousel-cards-add-new'
          size='normal'
          variation='tertiary'
          LeftIcon={<PlusIcon />}
          onClick={handleAddAccountClick}
        />
      </div>
      <div data-test={`carousel-cards-container-test`} className='carousel-cards-container' ref={carouselRef}>
        {isValidArray(filteredAccounts) &&
          selectedAccount &&
          filteredAccounts.map((account, i) => {
            const selected = account.id === selectedAccount.id
            const { currencyCode, balance, id, dedicatedMerchantBankAccounts, name } = account || {}
            const { iban } = dedicatedMerchantBankAccounts?.[0] || {}
            return (
              <div
                data-test={`carousel-cards-wrapper-${i}-test`}
                key={id}
                className={cn('carousel-cards-wrapper', {
                  active: selected,
                  'two-accounts': filteredAccounts.length <= 2
                })}
                onClick={() => {
                  if (selectable) {
                    selectAccountElement(0, i)
                  }
                }}>
                <div style={{ overflow: 'hidden', width: 'calc(100% - 3.2rem)' }}>
                  <div data-test={`carousel-cards-header-${i}-test`} className='carousel-cards-header'>
                    {currencyCode && <CurrencyIcon currency={currencyCode} circle size='4rem' />}
                    <div
                      data-test={`carousel-cards-currency-title-${i}-test`}
                      className='carousel-cards-currency-title'>
                      {isMobile
                        ? `${name ? (name?.length > 18 ? cutString(name, 18) : name) : 'N/A'}`
                        : name || currenciesAlpha3[currencyCode]?.name}
                    </div>
                  </div>
                  {/* <div data-test={`carousel-cards-header-dot-${i}-test`} className='carousel-cards-header-dot'></div> */}

                  <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content'>
                    <span translate='no'>
                      {getCurrencySymbol(currencyCode)} {balance}
                    </span>
                  </div>
                </div>
                {iban && (
                  <div data-test={`carousel-cards-content-${i}-test`} className='carousel-cards-content-iban'>
                    <div className='carousel-cards-label-wrapper'>
                      <IBANIcon />
                      <span style={{ maxWidth: 0 /*otherwise breaks responsiveness*/ }}>
                        {tr('FRONTEND.ACCOUNTS.IBAN', 'IBAN')}
                      </span>
                    </div>
                    <span style={{ maxWidth: 0 /*otherwise breaks responsiveness*/ }}>{iban}</span>
                  </div>
                )}
              </div>
            )
          })}
      </div>

      {isValidArray(filteredAccounts) && filteredAccounts.length > 2 && (
        <div className='carousel-arrows'>
          <button
            className='carousel-cards-arrow'
            onClick={e => {
              e.preventDefault()
              prev()
            }}>
            <LeftArrow />
          </button>
          <button
            className='carousel-cards-arrow'
            onClick={e => {
              e.preventDefault()
              next()
            }}>
            <RightArrow />
          </button>
        </div>
      )}
      {/* <div className='carousel-cards-dots'>
        {isValidArray(filteredAccounts) && filteredAccounts.length > 2 && 
          filteredAccounts.length > 1 &&
          filteredAccounts.map((_, index) => {
            return (
              <div
                className={cn('carousel-cards-dots-element', { active: filteredAccounts.indexOf(selectedAccount) == index })}
                key={'card-dot' + index}
                onClick={() => selectAccountElement(0, index)}>
                <span> </span>
              </div>
            )
          })}
      </div> */}
    </div>
  )
}

export default CarouselCards
