import { useContext, useState } from 'react'

import CoreButton from '@components/CoreButton'
import CoreInput from '@components/CoreInput'
import CoreRadioButton from '@components/CoreRadioButton'
import CoreSelect from '@components/CoreSelect'
import { useDownloadFileMutation } from '@hooks'
import CalendarIcon from '@images/icons/transaction-search-calendar.svg?react'
import fileDownload from 'js-file-download'

import { endpoints, useGetMerchantQuery } from 'mmfintech-backend-api'
import {
  GlobalContext,
  compareDates,
  currentDay,
  currentMonth,
  currentYear,
  firstDateOfMonth,
  fixDateOnly,
  lastDayOfMonth,
  monthOptions,
  prepareDate,
  todayDate,
  tr,
  useFormValues
} from 'mmfintech-commons'
import { ErrorDisplay } from 'mmfintech-portal-commons'

import { AccountBalanceResponse, StatementPeriodTypeEnum } from 'mmfintech-commons-types'

import './styled/exportTransactionFiles.scss'

interface IExportTransactionFilesProps {
  account: AccountBalanceResponse
  accounts: AccountBalanceResponse[]
  dateFrom: Date
  dateTo: Date
  exportType: 'statement' | 'CSV'
}

function ExportTransactionFiles({ account, accounts, dateFrom, dateTo, exportType }: IExportTransactionFilesProps) {
  const { modalHide } = useContext(GlobalContext)
  const { data: merchant } = useGetMerchantQuery()

  const [handleDownload, { isLoading: balanceStatementFetching, error: balanceStatementError }] =
    useDownloadFileMutation({
      endpointName: 'balancestatement/download'
    })

  const [error, setError] = useState(null)
  const [periodType, setPeriodType] = useState<keyof typeof StatementPeriodTypeEnum>(StatementPeriodTypeEnum.CUSTOM)
  const [selectedAccount, setSelectedAccount] = useState(account)

  const formValues = useFormValues({
    quarter: { value: 'Q1' },
    month: { value: currentMonth() + 1 },
    year: { value: currentYear() },
    startDate: { value: dateFrom || firstDateOfMonth(new Date()) },
    endDate: { value: dateTo || todayDate() }
  })

  // const fiscalMonths = ['M1', 'M2', 'M3', 'M4', 'M5', 'M6', 'M7', 'M8', 'M9', 'M10', 'M11', 'M12']
  const fiscalQuarters = ['Q1', 'Q2', 'Q3', 'Q4']

  const getYears = () => {
    const { createdOn } = merchant || {}
    const year2 = prepareDate(createdOn).getFullYear()
    const res = []
    for (let y = currentYear(); y >= year2; y -= 1) {
      res.push(y)
    }
    return res
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (!selectedAccount) {
      setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_ACCOUNT', 'Account is not selected.'))
      return
    }

    let period

    switch (periodType) {
      case StatementPeriodTypeEnum.MONTHLY: {
        const startDate = new Date(Number(formValues.getValue('year')), formValues.getValue('month') - 1, 1)
        if (compareDates(startDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        const endDate = lastDayOfMonth(startDate)
        if (endDate.getFullYear() === currentYear() && endDate.getMonth() === currentMonth()) {
          endDate.setDate(currentDay())
        }
        if (compareDates(endDate, todayDate()) > 0) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(startDate),
          endDate: fixDateOnly(endDate)
        }
        // period = {
        //   periodType,
        //   month: formValues.getValue('month'), // [ M1, M2, M3, M4, M5, M6, M7, M8, M9, M10, M11, M12 ]
        //   year: formValues.getValue('year')
        // }
        break
      }
      case StatementPeriodTypeEnum.QUARTERLY:
        period = {
          periodType,
          quarter: formValues.getValue('quarter').value, // [ Q1, Q2, Q3, Q4 ]
          year: formValues.getValue('year').value
        }
        break
      case StatementPeriodTypeEnum.YEARLY:
        period = {
          periodType,
          year: formValues.getValue('year').value
        }
        break
      default:
        if (
          compareDates(formValues.getValue('startDate').value, todayDate()) > 0 ||
          compareDates(formValues.getValue('endDate').value, todayDate()) > 0
        ) {
          setError(tr('FRONTEND.STATEMENT.EXPORT.ERROR_PERIOD', 'The period should be before current date.'))
          return
        }

        period = {
          periodType: StatementPeriodTypeEnum.CUSTOM,
          startDate: fixDateOnly(formValues.getValue('startDate')),
          endDate: fixDateOnly(formValues.getValue('endDate'))
        }
    }

    const data = {
      accountId: selectedAccount.id,
      period
    }

    if (exportType === 'statement') {
      try {
        const res = await handleDownload({ url: endpoints.transactions.getStatementPDF(), method: 'POST', body: data })
        if (res) {
          fileDownload(res, 'balance-statement.pdf', 'application/pdf')
          modalHide()
        }
      } catch (_err) {}
    }
    if (exportType === 'CSV') {
      try {
        const res = await handleDownload({ url: endpoints.transactions.getStatementCSV(), method: 'POST', body: data })
        if (res) {
          fileDownload(res, 'transactions.csv', 'text/csv')
          modalHide()
        }
      } catch (err) {}
    }
  }

  return (
    <div className='transaction-statement-modal-container' data-test='balance-statement-period-modal'>
      <form className='transaction-statement-modal-form' noValidate onSubmit={handleSubmit}>
        <CoreSelect
          data-test='export-transaction-filter-account-selection'
          type='account'
          label={tr('FRONTEND.STATEMENT.EXPORT.ACCOUNT_LABEL', 'Account')}
          options={accounts}
          required
          className='transaction-statement-modal-account-selection'
          onChange={(_, selectedAccount) => {
            setSelectedAccount(selectedAccount)
          }}
        />

        <CoreRadioButton
          data-test='export-transaction-filter-radio-button-group'
          flexDirection='row'
          radioGroupe={'balance-statement'}
          defaultValue={periodType}
          className='transaction-statement-modal-radio-group'
          buttons={[
            {
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_MONTHLY', 'Monthly'),
              value: StatementPeriodTypeEnum.MONTHLY,
              disabled: false
            },
            {
              label: tr('FRONTEND.STATEMENT.EXPORT.PERIOD_CUSTOM', 'Custom'),
              value: StatementPeriodTypeEnum.CUSTOM,
              disabled: false
            }
          ]}
          onClick={val => setPeriodType(val as keyof typeof StatementPeriodTypeEnum)}
        />

        {periodType === StatementPeriodTypeEnum.CUSTOM ? (
          <div className='transaction-statement-modal-period-wrap'>
            <CoreInput
              type='date'
              name='startDate'
              label='From'
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsStart
              rangeDates
              {...formValues.registerInput('startDate')}
              startDate={formValues.getValue('startDate')}
              endDate={formValues.getValue('endDate')}
              data-test='filter-startDate-date'
              clearable
            />
            <CoreInput
              type='date'
              name='endDate'
              label='To'
              LeftIcon={<CalendarIcon />}
              className='transaction-calendar-input'
              selectsEnd
              rangeDates
              {...formValues.registerInput('endDate')}
              startDate={formValues.getValue('startDate')}
              endDate={formValues.getValue('endDate')}
              data-test='filter-to-date'
              clearable
            />
          </div>
        ) : (
          <div className='transaction-statement-modal-period-year-month'>
            <CoreSelect
              type='default'
              data-test='date-field-year'
              label={tr('FRONTEND.STATEMENT.EXPORT.YEAR_LABEL', 'Year')}
              value={getYears().map(year => ({ label: year.toString(), value: year }))}
              options={getYears().map(year => ({ label: year.toString(), value: year }))}
              {...formValues.registerInput('year')}
              required
            />

            {periodType === StatementPeriodTypeEnum.MONTHLY && (
              <CoreSelect
                type='default'
                data-test='date-field-month'
                label={tr('FRONTEND.STATEMENT.EXPORT.MONTH_LABEL', 'Month')}
                options={monthOptions()}
                {...formValues.registerInput('month')}
                required
              />
            )}

            {periodType === StatementPeriodTypeEnum.QUARTERLY && (
              <CoreSelect
                type='default'
                data-test='date-field-quarter'
                label={tr('FRONTEND.STATEMENT.EXPORT.QUARTER_LABEL', 'Quarter')}
                options={fiscalQuarters.map(q => ({ label: q, value: q }))}
                {...formValues.registerInput('quarter')}
                required
              />
            )}
          </div>
        )}

        <ErrorDisplay error={[balanceStatementError, error]} />

        <CoreButton fullWidth title='Confirm' size='normal' isLoading={balanceStatementFetching} type='submit' />
      </form>
    </div>
  )
}

export default ExportTransactionFiles
