import toast from 'react-hot-toast'

import CoreButton from '@components/CoreButton'

import { useChangeCardStatusMutation } from 'mmfintech-backend-api'
import { tr, translateError } from 'mmfintech-commons'

interface UnfreezeCardProps {
  prepaidCardId?: string | number
}

export const UnfreezeCard = ({ prepaidCardId }: UnfreezeCardProps) => {
  const [changeCardStatus, { isLoading: isFreezing }] = useChangeCardStatusMutation()

  const handleCardUnfreeze = async () => {
    if (prepaidCardId) {
      const newCardStatus = 'ACTIVE'

      try {
        await changeCardStatus({
          prepaidCardId,
          prepaidCardStatus: newCardStatus
        }).unwrap()
      } catch (error) {
        const message = translateError(error)
        if (message) {
          toast.remove()
          toast.error(message)
        }
      }
    }
  }

  return (
    <div className='unfreeze-card-wrapper'>
      <div className='unfreeze-card-text'>
        {tr('FRONTEND.CARDS.SETTINGS.UNFREEZE_CARD.MODAL.TEXT', 'Your are about to Unfreeze your card.')}
      </div>
      <CoreButton
        fullWidth
        type='button'
        variation='primary'
        size='normal'
        title={tr('FRONTEND.CARDS.SETTINGS.UNFREEZE_CARD.MODAL.UNFREEZE_BUTTON', 'Unfreeze card')}
        isLoading={isFreezing}
        onClick={handleCardUnfreeze}
      />
    </div>
  )
}
